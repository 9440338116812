import CSVEditor from "./CSVEditor";
import DefaultEditor from "./DefaultEditor";
import TextEditor from "./TextEditor";

const Editor = ({ file, setFile, fileExtension }) => {
  const textFileExtensions = [
    // Plain text and markdown
    'txt', 'md', 'markdown', 
  
    // Logs and configuration files
    'log', 'conf', 'cfg', 'ini', 'env',
  
    // Data formats
    'json', 'xml', 'tsv', 'yml', 'yaml',
  
    // Scripting languages
    'sh', 'bash', 'zsh', 'bat', 'ps1', 'rb', 'py', 'pl', 'lua', 'tcl', 'r',
  
    // Web Development
    'htm', 'html', 'css', 'js', 'jsx', 'ts', 'tsx', 'php', 'asp', 'aspx', 'jsp', 'less', 'scss', 'sass',
  
    // Server and Infrastructure as Code
    'dockerfile', 'vagrantfile',
  
    // Source code
    'c', 'cpp', 'h', 'hpp', 'java', 'class', 'cs', 'vb', 'go', 'swift', 'm', 'rs',
  
    // SQL
    'sql',
  
    // Other Programming Languages
    'scala', 'kts', 'kt', 'groovy', 'clj', 'cljs',
  
    // Version control
    'gitignore', 'gitattributes', 'gitmodules',
  
    // Miscellaneous
    'toml', 'makefile', 'cmake', 'lock', 'd', 'asm', 's', 'f', 'for', 'f90', 'f95'
  ];

  const renderEditor = () => {
    if (fileExtension === null) return <div></div>;

    if (textFileExtensions.includes(fileExtension)) {
      return <TextEditor file={file} setFile={setFile} />;
    }

    if (fileExtension === 'csv') {
      return <CSVEditor file={file} setFile={setFile} />;
    }
    
    // Unknown file extension
    return <DefaultEditor fileExtension={fileExtension} />;
  }

  return renderEditor()
}

export default Editor