import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import Link from '@mui/material/Link';

import { useGetUserCredits } from "../../../hooks/useGetUserCredits.js";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Account',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  // const { loading, credits } = useGetUserCredits();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     navigate(PATH_AUTH.login, { replace: false });

  //     if (isMountedRef.current) {
  //       handleClose();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  // };

  const handleLogout = () => {
    try {
      logout();
      // navigate('/login', { replace: false });

      // if (isMountedRef.current) {
      //   handleClose();
      // }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={{
        pr: 0.4,
        textAlign: 'right',
      }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 400, color: 'rgb(208, 255, 221)' }} noWrap>
          {user?.displayName}
        </Typography>
        {/* <Typography variant="body2" noWrap sx={{ textDecoration: 'none', color: 'text.secondary' }} 
        component={RouterLink} to={PATH_DASHBOARD.user.account}>
            {credits || credits === 0 ? credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' Credits ▸' : "..."}
        </Typography> */}
      </Box>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          transform: 'scale(0.85)',
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        {/* <Divider sx={{ borderStyle: 'solid', borderColor: '#03150e' }} /> */}
        <MenuItem sx={{ m: 1 }}>
          <Link style={{ color: "white", textDecoration: "none" }} target="_blank" href="https://www.linkedin.com/in/jeroenmarchand/">
            Support
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
