import React from 'react';
import { Box, CircularProgress } from '@mui/material';


const FileInfo = ({ loading, file, fileExtension, fileSize }) => {
  // console.log(loading);
    if (loading) {
      return (
        <>
          <Box sx={{ display: 'inline-block', mr: 2, fontWeight: 500 }}>Loading file</Box>
          <CircularProgress sx={{ color: '#00dd3c' }} size="1rem" />
        </>
      )
    }
    else if (file) {
      return (
        <>
          <Box sx={{ display: 'inline-block', mr: 2, fontWeight: 500 }}>Current file</Box>
          <Box sx={{ display: 'inline-block', mr: 2, color: '#009F2A' }}>.{fileExtension}</Box>
          <Box sx={{ display: 'inline-block', color: 'rgb(208, 255, 221)', fontSize: '14px' }}>{fileSize}</Box>
        </>
      )
    }
    else {
      return (
        <>
          <Box sx={{ display: 'inline-block', mr: 2, fontWeight: 500 }}>No file selected</Box>
        </>
      )
    }
  }

export default FileInfo;