

  import { useState, useEffect } from 'react';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Papa from 'papaparse';

import { useSnackbar } from 'notistack';
import { set } from 'lodash';
import en from 'src/locales/en';


const CustomNoRowsMessage = () => {
  return <div style={{color: 'rgb(208, 255, 221, 0.8)'}}> No rows. Add rows and columns to start.</div>;
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer >
      {props.selection.length > 0 ? ( <Button onClick={props.deleteSelectedRows} startIcon={<DeleteIcon />}> Delete { props.selection.length } rows</Button>) : null}
      <Button onClick={props.createRow} startIcon={<AddIcon />}> Row</Button>
      {/* <Button onClick={props.createColumn} startIcon={<AddIcon />}> Column</Button> */}
    </GridToolbarContainer>
  );
}

const CSVEditor = ({ file, setFile }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [editingColumn, setEditingColumn] = useState(null);
  const [columnDialogOpen, setColumnDialogOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!file) return;
    console.log(file);
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: function (result) {
        const lengthArrays = {};

        // Only if there is data
        if (result.data.length === 0) {
          setColumns([]);
          setRows([]);
          setSelectionModel([]);
          setEditingColumn(null);
          return;
        }

        // Initialize arrays for each column
        Object.keys(result.data[0]).forEach((key) => {
          lengthArrays[key] = [];
        });

        // Populate arrays with lengths from each row
        result.data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (row[key] !== null && row[key] !== undefined) {
              const len = row[key].toString().length;
              lengthArrays[key].push(len);
            }
          });
        });

        // Sort the arrays and find the 90th percentile value for each column
        Object.keys(lengthArrays).forEach((key) => {
          const arr = lengthArrays[key].sort((a, b) => a - b);
          const index = Math.ceil(arr.length * 0.8) - 1;
          lengthArrays[key] = arr[index];
        });

        // Generate columns based on 90th percentile lengths
        const columns = Object.keys(result.data[0]).map((key) => {
          // Use some arbitrary multiplier to scale the length to a pixel width
          // You might want to adjust this based on your specific needs
          var width = Math.max(130, (lengthArrays[key] + 1) * 8);

          // If width is NaN (like for numeric values), use a default width
          if (isNaN(width)) width = 30;

          return {
            field: key,
            headerName: key,
            width: width,
            editable: true,
          };
        });

        setColumns(columns);
        // Drop empty rows
        // const rows = result.data.filter((row) => {
        //   return Object.values(row).some((value) => value !== null && value !== undefined && value !== '');
        // });
        const rows = result.data;
        const rowsWithId = rows.map((d, index) => ({ id: index + 1, ...d }));
        setRows(rowsWithId);
        setSelectionModel([]);
        setEditingColumn(null);
      },
    });
  }, [file]);

  const handleSetRows = (newRows) => {
    const rowsToSave = newRows.map(({ id, ...rest }) => rest);

    console.log('rowsToSave', rowsToSave);

    const csv = Papa.unparse(rowsToSave);

    console.log('csv', csv);

    const blob = new Blob([csv], { type: 'text/csv' });
    const newFile = new File([blob], file.name, { type: 'text/csv', lastModified: new Date() });
    setFile(newFile);
  }

  const processRowUpdate = (newRow) => {
    handleSetRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  };

  const deleteSelectedRows = () => {
    // If all rows are selected, clear the rows
    if (selectionModel.length === rows.length) {
      handleSetRows([]);
      return;
    }
    handleSetRows(rows.filter(row => !selectionModel.includes(row.id)));
  }

  const createRow = () => {
    // If there are no rows, create a column instead
    if (rows.length === 0) {
      createColumn();
      return;
    }

    const maxId = rows.reduce((max, row) => Math.max(max, row.id), 0);
    const newRow = { id: maxId + 1 };
    handleSetRows([...rows, newRow]);
  }

  const createColumn = () => {
    let columnName = 'New Column '+ (columns.length + 1);
    // If there are no rows, add a dummy row and the column
    if (rows.length === 0) {
      handleSetRows([{ id: 1, [columnName]: '' }]);
      return;
    }
      
    // Add the column to all rows
    handleSetRows(rows.map((row) => {
      const newRow = { ...row };
      newRow[columnName] = '';
      return newRow;
    }));
  }

  // const handleSave = () => {
  //   enqueueSnackbar('File saved', { variant: 'success' });
  //   const rowsToSave = rows.map(({ id, ...rest }) => rest);
  //   const csv = Papa.unparse(rowsToSave);
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const newFile = new File([blob], file.name, { type: 'text/csv', lastModified: new Date() });
  //   setFile(newFile);
  // }

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData.getData('Text');
    const pastedData = clipboardData.trim().split('\n');
    const maxId = rows.reduce((max, row) => Math.max(max, row.id), 0);
    const newRows = pastedData.map((keywords, index) => ({ id: maxId + index + 1, keywords }));
    handleSetRows([...rows, ...newRows]);
  }

  const handleEditColumnClick = (column) => {
    setEditingColumn(column);
    setColumnDialogOpen(true);
  }

  const handleDeleteColumnClick = (column) => {
    handleSetRows(rows.map((row) => {
      const newRow = { ...row };
      delete newRow[column.field];
      return newRow;
    }));
  }

  const handleColumnDialogClose = () => {
    setColumnDialogOpen(false);
    setNewColumnName('');
  }

  const handleColumnNameChange = (event) => {
    setNewColumnName(event.target.value);
  }

  const handleColumnDialogSave = () => {
    // handleSetRows(rows.map((row) => {
    //   const newRow = { ...row };
    //   newRow[newColumnName] = newRow[editingColumn.field];
    //   delete newRow[editingColumn.field];
    //   return newRow;
    // }));
    
    // Update the column names in the rows while preserving the order
    const newRows = rows.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        if (key === editingColumn.field) {
          newRow[newColumnName] = row[key];
        } else {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });
    handleSetRows(newRows);

    handleColumnDialogClose();
    setNewColumnName('');
  }

  return (
    // <div style={{ height: 'calc(90vh - 150px)'}} onPaste={handlePaste} >
    <div style={{ height: 'calc(100vh - 450px)' }}>
      <DataGrid
        rows={rows}
        // columns={calculatedColumns}
        columns={columns.map((column) => ({
          ...column,
          renderHeader: (params) => (
            <>
              {column.headerName}
              {/* <IconButton onClick={() => handleEditColumnClick(column)} align='top' sx={{ ml: 1, marginBottom: '2px' }}>
                <EditIcon style={{ fontSize: '10px', color: "#c0efcd" }} />
              </IconButton>
              <IconButton onClick={() => handleDeleteColumnClick(column)} align='top' sx={{ ml: 1, marginBottom: '2px' }}>
                <DeleteIcon style={{ fontSize: '10px', color: "#c0efcd" }} />
              </IconButton> */}
            </>
          )
        }))}
        editMode="row"
        disableSelectionOnClick
        density="compact"
        checkboxSelection
        localeText={{
          noRowsLabel: <CustomNoRowsMessage />,
        }}
        // onCellBlur={handleCellBlur}
        onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
        selectionModel={selectionModel}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: (props) => <CustomToolbar createRow={createRow} createColumn={createColumn} deleteSelectedRows={deleteSelectedRows} 
          pasteRows={handlePaste} selection={selectionModel} {...props} />,
        }}
        classes={{ center: { textAlign: 'center' }}}
        loading={false}
        sx={{
          '& .MuiButton-root': {
            fontSize: '13px',
            fontWeight: '400',
            letterSpacing: '0.1px',
            marginRight: '10px !important',
            color: 'rgb(208, 255, 221) !important',
          },
          '& .MuiButton-root:nth-child(1)': {
            color: '#00b630 !important',
            fontWeight: 500,
          },
          '& .MuiButton-root:nth-child(2)': {
            color: rows?.length === 0 || selectionModel?.length != 0 ? '#00b630 !important' : 'rgb(208, 255, 221) !important',
            fontWeight: rows?.length === 0 || selectionModel?.length != 0 ? 500 : 400,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '15px',
          },
          '& .MuiDataGrid-main': {
            backgroundColor: '#051f16',
          },
          '& .MuiDataGrid-toolbarContainer': {
            background: 'none',
            justifyContent: 'start',
            paddingBottom: '15px',
          },
          '& .MuiDataGrid-cell': {
            padding: '10px 15px',
            borderBottom: 'none',
            backgroundColor: '#071b1394',
            color: '#e4fdea',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
            backgroundColor: '#071b13',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '14px 15px 14px 15px',
            backgroundColor: '#051b13',
            color: '#ffffff',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            padding: '5px',
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: '15px !important',
            height: '15px !important',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '& .MuiDataGrid-selectedRowCount': {
            color: 'rgb(208, 255, 221) !important',
          },
          '& .MuiTablePagination-root': {
            color: 'rgb(208, 255, 221) !important',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.27em',
            height: '0.27em',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            // backgroundColor: '#888',
            backgroundColor: 'rgb(208, 255, 221, 0.6)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      />

      <Dialog open={columnDialogOpen} onClose={handleColumnDialogClose}>
        <DialogTitle>Rename Column</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Column Name"
            type="text"
            fullWidth
            value={newColumnName}
            onChange={handleColumnNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleColumnDialogClose}>Cancel</Button>
          <Button onClick={handleColumnDialogSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CSVEditor;