import { useState } from "react";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { getBezierPath, getSmoothStepPath, getSimpleBezierPath, useNodes  } from "react-flow-renderer"
import { keyframes } from "styled-components";
import { useSnackbar } from "notistack";

import withRouter from "../../hocs/withRouter.js";
import { deleteAlgorithmConnector } from "../../redux/slices/algorithm.js";
import en from "src/locales/en.js";


function Connector(props) {
  const { id, source, sourceHandleId, target, targetHandleId, runOnly } = props;
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {} } = props;

  const actions = useNodes();
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();

  const [hover, setHover] = useState(false);

  // const algorithmId = props.params.id;

  const sourceAction = actions.find(x => x.id === source);
  const targetAction = actions.find(x => x.id === target);
  // const targetInput = targetAction.data.inputs[targetHandleId];
  // const sourceOutput = sourceAction.data.outputs[sourceHandleId];
  // const filename = sourceOutput.name + '.' + sourceOutput.extension;

  // const sourceTargetPositions = { sourceX, sourceY, sourcePosition, targetX: targetX - 4, targetY, targetPosition, }
  // const labelSpace = "M" + (targetX - 4) + " " + targetY + " l" + 4 + " " + 0;
  // const connectorPath = getBezierPath(sourceTargetPositions) + labelSpace;
  // const dashdraw = keyframes` 0% { stroke-dashoffset: 49 } `

  const sourceTargetPositions = { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition }
  const connectorPath = getBezierPath(sourceTargetPositions);
  // const connectorPath = getSimpleBezierPath(sourceTargetPositions);
  const dashdraw = keyframes` 0% { stroke-dashoffset: 49 } `

  const deleteConnector = () => {
    // if (runOnly) enqueueSnackbar('Add your OpenAI API key first', { variant: 'warning' });
    // else dispatch(deleteAlgorithmConnector(algorithmId, id, source, target));
  }

  return (
    <>
      <g onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        onClick={deleteConnector} style={{ cursor: 'pointer' }}>
        <path id={id} className="react-flow__edge-path" d={connectorPath} style={{
          ...style,
          stroke: "rgba(2, 142, 40, 0.75)", transition: "stroke 0.7s ease 0.1s", strokeDasharrayOffset: 49, strokeWidth: 44,
          strokeOpacity: targetAction.data.status === 'completed' || targetAction.data.status === 'running' || targetAction.data.status === 'build_error' || targetAction.data.status === 'run_error' || targetAction.data.status === 'input_error' || targetAction.data.status === 'testing' ? "100%" : "40%",
          strokeDasharray: targetAction.data.status === 'running' || targetAction.data.status === 'testing' || sourceAction.data.status === 'running' || sourceAction.data.status === 'testing' ? "49 0.7" : "none",
          animation: targetAction.data.status === 'running' || targetAction.data.status === 'testing' || sourceAction.data.status === 'running' || sourceAction.data.status === 'testing' ? dashdraw + " 2s linear infinite" : "none",
        }} />
      </g>
      <text onClick={deleteConnector} style={{ cursor: 'pointer' }}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <textPath
          href={`#${id}`} style={{ fontSize: '18px' }} startOffset="94%" textAnchor="end"
          fill="#D0FFDD" fontFamily="Outfit" letterSpacing="0.5px" fontWeight="500"
          alignmentBaseline="middle"
        >
          {/* { hover ? (filename + '\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A✕\u200A\u200A') : null } */}
        </textPath>
      </text>
    </>
  )
}

const enhance = compose(withRouter);
export default enhance(Connector);