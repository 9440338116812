import { Handle, Position, useNodes, useEdges } from "react-flow-renderer"


const FileActionHandle = ({ id, actionId, isConnected, connecting, label, type, active }) => {
  const actions = useNodes();
  const connectors = useEdges();

  const ouputConnectors = connectors.filter(x => x.source === actionId);
  const outputActions = actions.filter(x => ouputConnectors.find(y => y.target === x.id));
  const activeOutputActions = outputActions.filter(x => x.data.status === 'completed' || x.data.status === 'running' || x.data.status === 'run_error').length;
  const inactiveOutputActions = outputActions.filter(x => x.data.status !== 'completed').length;

  const calculateOverlay = (colors) => {
    if (colors.length === 0) {
      return [0, 0, 0, 0];  // Return a default value (black, fully transparent here) for an empty list
    }
  
    let alpha_r = colors[0][3];
    let color_r = [...colors[0]];
  
    for (let i = 1; i < colors.length; i++) {
      const [r, g, b, alpha] = colors[i];
      
      alpha_r = alpha_r + alpha * (1 - alpha_r);
      color_r = [
        (color_r[3] * color_r[0] + alpha * r * (1 - color_r[3])) / alpha_r,
        (color_r[3] * color_r[1] + alpha * g * (1 - color_r[3])) / alpha_r,
        (color_r[3] * color_r[2] + alpha * b * (1 - color_r[3])) / alpha_r,
        alpha_r,
      ];
    }
  
    return color_r;
  }

  const outputHandleColor = () => {
    // Create the list of colors to overlay based on the number of completed and uncompleted output actions
    const colors = [];
    for (let i = 0; i < activeOutputActions; i++) {
      colors.push([2, 142, 40, 0.75]);
    }
    for (let i = 0; i < inactiveOutputActions; i++) {
      // Edited for stroke opacity
      colors.push([2, 142, 40, 0.3]);
    }
    // Calculate the overlay color
    const overlayColor = calculateOverlay(colors);
    // Return the overlay color
    return `rgba(${overlayColor[0]}, ${overlayColor[1]}, ${overlayColor[2]}, ${overlayColor[3]})`;
  }


  const isValidConnection = (connection) => {
    const sourceAction = actions.find(action => action.id === connection.source);
    const targetAction = actions.find(action => action.id === connection.target);

    const sourceOutput = sourceAction.data.outputs[connection.sourceHandle];
    const targetInput = targetAction.data.inputs[connection.targetHandle];

    // Do not allow connection to itself
    if (sourceAction.id === targetAction.id) return false;
    // If source action type is file and target action is auto_code and vice versa
    if (sourceAction.type === "file" && targetAction.type === "file") return false;
    // Check if sourceOutput or targetInput is a file without a filename (with extension)
    if (sourceAction.type === 'file' && sourceOutput.name === "" && sourceOutput.extension === "" && targetInput.name == "" && targetInput.extension == "") return false;
    if (targetAction.type === 'file' && targetInput.name === "" && targetInput.extension === "" && sourceOutput.name == "" && sourceOutput.extension == "") return false;
    // If sourceOutput and targetInput have the same name and extension
    if (sourceOutput.name === targetInput.name && sourceOutput.extension === targetInput.extension) return true;
    // If sourceOutput file filename (with extension) is set and targetInput isn't
    if (sourceOutput.name !== "" && sourceOutput.extension !== "" && targetInput.name === "" && targetInput.extension === "") {
      // Check if sourceOutput filename (with extension) isn't already in targetAction inputs or outputs
      const targetActionInputs = Object.values(targetAction.data.inputs);
      const targetActionOutputs = Object.values(targetAction.data.outputs);
      const targetActionInputsAndOutputs = targetActionInputs.concat(targetActionOutputs);
      const targetActionInputsAndOutputsNames = targetActionInputsAndOutputs.map(inputOrOutput => inputOrOutput.name + "." + inputOrOutput.extension);
      if (!targetActionInputsAndOutputsNames.includes(sourceOutput.name + "." + sourceOutput.extension)) return true;
    }
    // If targetInput filename (with extension) is set and sourceOutput isn't
    if (targetInput.name !== "" && targetInput.extension !== "" && sourceOutput.name === "" && sourceOutput.extension === "") {
      // Check if targetInput filename (with extension) isn't already in sourceAction inputs or outputs
      const sourceActionInputs = Object.values(sourceAction.data.inputs);
      const sourceActionOutputs = Object.values(sourceAction.data.outputs);
      const sourceActionInputsAndOutputs = sourceActionInputs.concat(sourceActionOutputs);
      const sourceActionInputsAndOutputsNames = sourceActionInputsAndOutputs.map(inputOrOutput => inputOrOutput.name + "." + inputOrOutput.extension);
      if (!sourceActionInputsAndOutputsNames.includes(targetInput.name + "." + targetInput.extension)) return true;
    }

    return false;
  }

  const connectable = () => {
    // Currently not dragging a connector
    if (!connecting) return true;
    // The current handle that is being dragged should be visible
    if (connecting.nodeId === actionId && connecting.handleId === id)  return true;
    // Cannot connect to itself
    if (connecting.nodeId === actionId) return false;
    // Can only connect source to target and target to source
    if (connecting.handleType === type) return false;
    const source = connecting.handleType === "source" ? connecting.nodeId : actionId;
    const sourceHandle = connecting.handleType === "source" ? connecting.handleId : id;
    const target = connecting.handleType === "target" ? connecting.nodeId : actionId;
    const targetHandle = connecting.handleType === "target" ? connecting.handleId : id;
    // Check if the connection is valid
    return isValidConnection({ source, sourceHandle, target, targetHandle });
  }



  const isConnectable = connectable();
  const handleStyles = { background: "transparent", height: 43,  }
  const position = type === "target" ? Position.Left : Position.Right;

  if (isConnected && type === "source") {
       return <Handle type={type} position={position} id={id} key={id} style={{
      ...handleStyles, width: 1, backgroundColor: outputHandleColor(),
      marginLeft: type === "target" ? -250 : 0, left: type === "target" ? 0 : 0,
    }} />;
  }
  else if (isConnected && type === "target") {
    return <Handle type={type} position={position} id={id} key={id} style={{ 
      ...handleStyles, width: 1, marginLeft: -1, backgroundColor: "rgb(3 93 31)",
     }} />;
  } else {
    return (
      <div>
        <Handle type={type} position={position} id={id} key={id} isValidConnection={isValidConnection} style={{ 
          ...handleStyles, width: 1, margin: type === "target" ? "0 0 0 -1px" : "0 -1px 0 0",
           }}>
        </Handle>
      </div>
    )
  }
}

export default FileActionHandle;

        // <div style={{
        //   position: "absolute",
        //   zIndex: -1,
        //   lineHeight: "43px",
        //   height: "43px",
        //   margin: type === "target" ? "-43px 0 0 -150px" : "-43px -150px 0 0",
        //   background: connecting && isConnectable || active ? 
        //     type === "target" ?
        //       "linear-gradient(90deg, #006e2100 0%, #006e2180 60%, #006e21 90%)" :
        //       "linear-gradient(270deg, #006e2100 0%, #006e2180 60%, #006e21 90%)" :
        //     type === "target" ? 
        //       "linear-gradient(90deg, #01261800 0%, #01261880 30%, #012618 60%)" :
        //       "linear-gradient(270deg, #01261800 0%, #01261880 30%, #012618 60%)",
        // }}>
        //   <div style={{
        //     display: "inline-block",
        //     width: "150px",
        //     paddingRight: type === "target" ? 35 : 0,
        //     paddingLeft: type === "target" ? 0 : 35,
        //     fontFamily: "Outfit",
        //     fontSize: "18px",
        //     color: "rgb(208 255 221 / 90%)",
        //     letterSpacing: "0.3px",
        //     fontWeight: "400",
        //     textAlign: type === "target" ? "right" : "left",
        //   }}>
        //     {/* {label === "all" ? "" : label.substring(0, 17) } */}
        //   </div>
        // </div>