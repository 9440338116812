import { Handle, Position, useNodes, useEdges } from "react-flow-renderer"

import _ from "lodash";


const AutoCodeActionHandle = ({ id, actionId, isConnected, connecting, label, type, active }) => {
  const actions = useNodes();

  const currentAction = actions.find(x => x.id === actionId);

  const isValidConnection = (connection) => {
    const sourceAction = actions.find(action => action.id === connection.source);
    const targetAction = actions.find(action => action.id === connection.target);

    const sourceOutput = sourceAction.data.outputs[connection.sourceHandle];
    const targetInput = targetAction.data.inputs[connection.targetHandle];

    // Do not allow connection to itself
    if (sourceAction.id === targetAction.id) return false;
    // If source action type is file and target action is auto_code and vice versa
    if (sourceAction.type === "auto_code" && targetAction.type === "auto_code") return false; 
    // Check if sourceOutput or targetInput is a file without a filename (with extension)
    if (sourceAction.type === 'file' && sourceOutput.name === "" && sourceOutput.extension === "" && targetInput.name == "" && targetInput.extension == "") return false;
    if (targetAction.type === 'file' && targetInput.name === "" && targetInput.extension === "" && sourceOutput.name == "" && sourceOutput.extension == "") return false;
    // If sourceOutput and targetInput have the same name and extension
    if (sourceOutput.name === targetInput.name && sourceOutput.extension === targetInput.extension) return true;
    // If sourceOutput filename (with extension) is set and targetInput isn't
    if (sourceOutput.name !== "" && sourceOutput.extension !== "" && targetInput.name === "" && targetInput.extension === "") {
      // Check if sourceOutput filename (with extension) isn't already in targetAction inputs or outputs
      const targetActionInputs = Object.values(targetAction.data.inputs);
      const targetActionOutputs = Object.values(targetAction.data.outputs);
      const targetActionInputsAndOutputs = targetActionInputs.concat(targetActionOutputs);
      const targetActionInputsAndOutputsNames = targetActionInputsAndOutputs.map(inputOrOutput => inputOrOutput.name + "." + inputOrOutput.extension);
      if (!targetActionInputsAndOutputsNames.includes(sourceOutput.name + "." + sourceOutput.extension)) return true;
    }
    // If targetInput filename (with extension) is set and sourceOutput isn't
    if (targetInput.name !== "" && targetInput.extension !== "" && sourceOutput.name === "" && sourceOutput.extension === "") {
      // Check if targetInput filename (with extension) isn't already in sourceAction inputs or outputs
      const sourceActionInputs = Object.values(sourceAction.data.inputs);
      const sourceActionOutputs = Object.values(sourceAction.data.outputs);
      const sourceActionInputsAndOutputs = sourceActionInputs.concat(sourceActionOutputs);
      const sourceActionInputsAndOutputsNames = sourceActionInputsAndOutputs.map(inputOrOutput => inputOrOutput.name + "." + inputOrOutput.extension);
      if (!sourceActionInputsAndOutputsNames.includes(targetInput.name + "." + targetInput.extension)) return true;
    }

    return false;
  }

  const connectable = () => {
    // Currently not dragging a connector
    if (!connecting) return true;
    // The current handle that is being dragged should be visible
    if (connecting.handleId === id) return true;
    // Can only connect source to target and target to source 
    if (connecting.handleType === type) return false;

    const source = connecting.handleType === "source" ? connecting.nodeId : actionId;
    const sourceHandle = connecting.handleType === "source" ? connecting.handleId : id;
    const target = connecting.handleType === "target" ? connecting.nodeId : actionId;
    const targetHandle = connecting.handleType === "target" ? connecting.handleId : id;
    return isValidConnection({ source, sourceHandle, target, targetHandle });
  }

  const isConnectable = connectable();
  const handleStyles = { marginBottom: 32, height: 43, top: 3, backgroundColor: "transparent", }
  const position = type === "source" ? Position.Right : Position.Left;

  if (isConnected && type === "source") {
       return <Handle type={type} position={position} id={id} key={id} style={{
      ...handleStyles, width: 1, marginLeft: type === "target" ? -200 : 0, left: type === "target" ? 0 : 0,
      backgroundColor: currentAction.data.status === 'completed' ? "#017322" : "#003e16",
    }} />;

  } else if (isConnected && type === "target") {
    return <Handle type={type} position={position} id={id} key={id} style={{
      ...handleStyles, width: 1, marginLeft: -4,
    }} />;

  } else {
    return (
      <div>
        <Handle type={type} position={position} id={id} key={id} isValidConnection={isValidConnection} style={{
          ...handleStyles,
          width: 200, left: type === "target" ? 0 : 35,
          marginLeft: type === "target" ? -200 : 0,
        }}>
        </Handle>
        <div style={{
          position: "absolute",
          zIndex: -1,
          transition: "all 2.7s ease 0.1s",
          background: connecting && isConnectable || active ? 
            type === "target" ?
              "linear-gradient(90deg, #006e2100 0%, #006e2180 60%, #006e21 100%)" :
              "linear-gradient(270deg, #006e2100 0%, #006e2180 60%, #006e21 100%)" :
            type === "target" ? 
              "linear-gradient(90deg, #01261800 0%, #01261880 30%, #012618 60%)" :
              "linear-gradient(270deg, #01261800 0%, #01261880 30%, #012618 60%)",
          lineHeight: "43px",
          height: "43px",
          marginLeft: type === "target" ? -200 : 0,
          left: type === "target" ? 0 : 280,
          marginTop: "-75px",
        }}>
          <div style={{
            display: "inline-block",
            width: "200px",
            paddingRight: type === "target" ? 35 : 0,
            paddingLeft: type === "target" ? 0 : 35,
            fontFamily: "Outfit",
            fontSize: "18px",
            color: "#D0FFDD",
            letterSpacing: "0.3px",
            fontWeight: "400",
            textAlign: type === "target" ? "right" : "left",
          }}>
            { label.substring(0, 15) }
          </div>
        </div>
      </div>
    )
  }
}

export default AutoCodeActionHandle