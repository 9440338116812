import * as React from "react"
import IconButton from "@mui/material/IconButton"
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid"
// import DeleteIcon from "@mui/icons-material/Delete"
import { FontAwesomeIcon, faMagnifyingGlassPlus } from "@fortawesome/react-fontawesome"
import { faDelete, faRemove } from "@fortawesome/free-solid-svg-icons"


const _rows = [
  { id: 1, name: "trap", ctr: 80 },
  { id: 2, name: "trap geverfd", ctr: 80 },
  { id: 3, name: "s trap", ctr: 80 },
  { id: 4, name: "trap tegels", ctr: 80 },
  { id: 5, name: "reken trap", ctr: 80 },
  { id: 6, name: "tegels trap", ctr: 80 },
  { id: 7, name: "trap stucen", ctr: 80 },
  { id: 8, name: "doppen trap", ctr: 80 },
  { id: 9, name: "trap led", ctr: 80 },
  { id: 10, name: "bekisting trap", ctr: 80 },
  { id: 11, name: "trap inmeten", ctr: 80 },
  { id: 12, name: "trap frezen", ctr: 80 },
  { id: 13, name: "leuning trap", ctr: 80 },
  { id: 14, name: "rvs trap", ctr: 80 },
  { id: 15, name: "benaming trap", ctr: 80 },
  { id: 16, name: "taupe trap", ctr: 80 },
  { id: 17, name: "beuken trap", ctr: 78 },
  { id: 18, name: "loper trap", ctr: 78 },
  { id: 19, name: "trap versiering", ctr: 78 },
  { id: 20, name: "trap xl", ctr: 78 },
  { id: 21, name: "trap behangen", ctr: 78 },
  { id: 22, name: "lak trap", ctr: 78 },
  { id: 23, name: "geverfde trap", ctr: 78 },
  { id: 24, name: "trappe", ctr: 78 },
  { id: 25, name: "trapmaat", ctr: 78 },
  { id: 26, name: "trap in beuk", ctr: 78 },
  { id: 27, name: "kast trap", ctr: 78 },
  { id: 28, name: "siergrind trap", ctr: 78 },
  { id: 29, name: "trap rvs", ctr: 78 },
  { id: 30, name: "trap blanke lak", ctr: 78 },
  { id: 31, name: "trap tegel", ctr: 75 },
  { id: 32, name: "entresol trap", ctr: 75 },
  { id: 33, name: "laminaat trap", ctr: 75 },
  { id: 34, name: "vaste trap", ctr: 75 },
  { id: 35, name: "trap x trap", ctr: 75 },
  { id: 36, name: "trap beuk", ctr: 75 },
  { id: 37, name: "trap hubo", ctr: 75 },
  { id: 38, name: "trap detail", ctr: 75 },
  { id: 39, name: "steentapijt trap", ctr: 75 },
  { id: 40, name: "kraken trap", ctr: 75 },
  { id: 41, name: "gladde trap", ctr: 75 },
  { id: 42, name: "afmeting trap", ctr: 75 },
  { id: 43, name: "handleuning trap", ctr: 75 },
  { id: 44, name: "wat is trap", ctr: 69 },
  { id: 45, name: "trap wasrek", ctr: 69 },
  { id: 46, name: "trap/u", ctr: 69 },
  { id: 47, name: "grenen trap", ctr: 69 },
  { id: 48, name: "steigerhout trap", ctr: 69 },
  { id: 49, name: "stapelbed trap", ctr: 69 },
  { id: 50, name: "trap in steigerhout", ctr: 69 },
  { id: 51, name: "opvouwbare trap", ctr: 69 },
  { id: 52, name: "trapkast", ctr: 69 },
  { id: 53, name: "trap wandrek", ctr: 69 },
  { id: 54, name: "tweekwart trap", ctr: 69 },
  { id: 55, name: "hellingshoek trap", ctr: 69 },
  { id: 56, name: "trapverven", ctr: 69 },
  { id: 57, name: "trapkast inrichten", ctr: 69 },
  { id: 58, name: "trap in kast", ctr: 69 },
  { id: 59, name: "beukenhout trap", ctr: 69 },
  { id: 60, name: "trap 61", ctr: 69 },
  { id: 62, name: "meranti trap", ctr: 69 },
  { id: 63, name: "plakstrips trap", ctr: 69 },
  { id: 64, name: "trap cover", ctr: 69 },
  { id: 65, name: "gelakte trap", ctr: 55 },
  { id: 66, name: "definitie trap", ctr: 55 },
  { id: 67, name: "inklapbare trap", ctr: 55 },
  { id: 68, name: "ronde trap", ctr: 55 },
  { id: 69, name: "trap design", ctr: 55 },
  { id: 70, name: "kast in trapkast", ctr: 55 },
  { id: 71, name: "trap vliering", ctr: 55 },
  { id: 72, name: "trap 73", ctr: 55 },
  { id: 74, name: "trapmattenset", ctr: 55 },
  { id: 75, name: "trap bereken", ctr: 55 },
  { id: 76, name: "trap step", ctr: 55 },
  { id: 77, name: "laminaat trappen", ctr: 55 },
  { id: 78, name: "tapijt trap", ctr: 55 },
  { id: 79, name: "open trap", ctr: 55 },
  { id: 80, name: "trap 81", ctr: 55 },
  { id: 82, name: "plint trap", ctr: 55 },
  { id: 83, name: "trap in laminaat", ctr: 55 },
  { id: 84, name: "trap open", ctr: 55 },
  { id: 85, name: "z trap", ctr: 55 },
  { id: 86, name: "trap beukenhout", ctr: 44 },
  { id: 87, name: "vries trappen", ctr: 44 },
  { id: 88, name: "soort trap", ctr: 44 },
  { id: 89, name: "prefab trappen", ctr: 44 },
  { id: 90, name: "antraciet trap", ctr: 44 },
  { id: 91, name: "led trap", ctr: 44 },
  { id: 92, name: "trap strip", ctr: 44 },
  { id: 93, name: "trap net", ctr: 44 },
  { id: 94, name: "trap model", ctr: 44 },
  { id: 95, name: "traphekje open trap", ctr: 44 },
  { id: 96, name: "steilheid trap", ctr: 44 },
  { id: 97, name: "meet trap", ctr: 44 },
  { id: 98, name: "modern trap", ctr: 44 },
  { id: 99, name: "bouwtekening trap", ctr: 44 },
  { id: 100, name: "blanke lak trap", ctr: 44 },
  { id: 101, name: "trap lak", ctr: 44 },
  { id: 102, name: "traphal", ctr: 44 },
  { id: 103, name: "open trap kast", ctr: 44 },
  { id: 104, name: "trapboom", ctr: 23 },
  { id: 105, name: "trapgat", ctr: 23 },
  { id: 106, name: "trap modern", ctr: 23 },
  { id: 107, name: "open trap traphekje", ctr: 23 },
  { id: 108, name: "linde trappen", ctr: 23 },
  { id: 109, name: "pinterest trappen", ctr: 23 },
  { id: 110, name: "trap kast", ctr: 23 },
  { id: 111, name: "beits trap", ctr: 23 },
  { id: 112, name: "trap tapijt", ctr: 23 },
  { id: 113, name: "trap expert", ctr: 23 },
  { id: 114, name: "karwei trap", ctr: 23 },
  { id: 115, name: "trap care", ctr: 23 },
  { id: 116, name: "behangen trapgat", ctr: 21 },
  { id: 117, name: "trap pvc", ctr: 21 },
  { id: 118, name: "laminaat leggen trap", ctr: 21 },
  { id: 119, name: "trapstrip", ctr: 21 },
  { id: 120, name: "trapconstructie", ctr: 21 },
  { id: 121, name: "opbergkast trapkast", ctr: 21 },
  { id: 122, name: "vurenhout trap", ctr: 21 },
  { id: 123, name: "trapleuning ronde trap", ctr: 21 },
  { id: 124, name: "trapprofiel", ctr: 21 },
  { id: 125, name: "dubbelkwart trap", ctr: 21 },
  { id: 126, name: "wand trap", ctr: 21 },
  { id: 127, name: "trap vurenhout", ctr: 21 },
  { id: 128, name: "eisen trap", ctr: 21 },
  { id: 129, name: "trapleer", ctr: 21 },
  { id: 130, name: "trap cm", ctr: 21 },
  { id: 131, name: "open trap of dichte trap", ctr: 21 },
  { id: 132, name: "trap folie", ctr: 19 },
  { id: 133, name: "sisal trap", ctr: 19 },
  { id: 134, name: "trapformule", ctr: 19 },
  { id: 135, name: "trap met loper", ctr: 19 },
  { id: 136, name: "laminaat trap leggen", ctr: 19 },
  { id: 137, name: "de liter trap", ctr: 19 },
  { id: 138, name: "tapijt loper trap", ctr: 19 },
  { id: 139, name: "trap beits", ctr: 19 },
  { id: 140, name: "matjes trap", ctr: 19 },
  { id: 141, name: "open trap verven", ctr: 19 },
  { id: 142, name: "spotjes trap", ctr: 19 },
  { id: 143, name: "trap eisen", ctr: 19 },
  { id: 144, name: "krakende trap", ctr: 19 },
  { id: 145, name: "stalen trap", ctr: 19 },
  { id: 146, name: "traprenovatie trap", ctr: 19 },
  { id: 147, name: "smalle trap", ctr: 19 },
  { id: 148, name: "plak laminaat trap", ctr: 19 },
  { id: 149, name: "open trap leuning", ctr: 19 },
  { id: 150, name: "trapbomen", ctr: 19 },
  { id: 151, name: "trapmatten", ctr: 19 },
  { id: 152, name: "trap palen", ctr: 19 },
  { id: 153, name: "voorbeeld trap", ctr: 19 },
  { id: 154, name: "trapspijlen", ctr: 19 },
  { id: 155, name: "ikea trap", ctr: 19 },
  { id: 156, name: "trap laminaat", ctr: 19 },
  { id: 157, name: "rvs leuning trap", ctr: 19 },
  { id: 158, name: "trapmat", ctr: 19 },
  { id: 159, name: "trapleuning open trap", ctr: 19 },
  { id: 160, name: "trapbox", ctr: 19 },
  { id: 161, name: "moderne trap", ctr: 15 },
  { id: 162, name: "merbau trap", ctr: 15 },
  { id: 163, name: "tapijtlijm trap", ctr: 15 },
  { id: 164, name: "vuren trap", ctr: 15 },
  { id: 165, name: "inrichting trapkast", ctr: 15 },
  { id: 166, name: "trapleuning verven", ctr: 15 },
  { id: 167, name: "scheluwe trap", ctr: 15 },
  { id: 168, name: "pvc trap", ctr: 15 },
  { id: 169, name: "trap steps", ctr: 15 },
  { id: 170, name: "trap bekleed met leer", ctr: 15 },
  { id: 171, name: "trap wit", ctr: 15 },
  { id: 172, name: "trap karwei", ctr: 15 },
  { id: 173, name: "vernis trap", ctr: 15 },
  { id: 174, name: "traploper open trap", ctr: 15 },
  { id: 175, name: "trap tekening", ctr: 15 },
  { id: 176, name: "trap 177", ctr: 15 },
  { id: 178, name: "baluster trap", ctr: 15 },
  { id: 179, name: "laminaat trap rand", ctr: 15 },
  { id: 180, name: "trap 181dehands", ctr: 15 },
  { id: 182, name: "traptapijten", ctr: 15 },
  { id: 183, name: "trapconstructies", ctr: 15 },
  { id: 184, name: "eiken trap", ctr: 15 },
  { id: 185, name: "karwei trapmatten", ctr: 15 },
  { id: 186, name: "houder trapleuning", ctr: 15 },
  { id: 187, name: "trap met laminaat", ctr: 15 },
  { id: 188, name: "laminaat open trap", ctr: 15 },
  { id: 189, name: "laminaat bij trap", ctr: 15 },
  { id: 190, name: "gelakte trap verven", ctr: 15 },
  { id: 191, name: "compacte trap", ctr: 15 },
  { id: 192, name: "traps trainen", ctr: 15 },
  { id: 193, name: "traphekje ronde trap", ctr: 15 },
  { id: 194, name: "trap plateau", ctr: 15 },
  { id: 195, name: "brocante trap", ctr: 15 },
  { id: 196, name: "trap dwg", ctr: 15 },
  { id: 197, name: "balustrade trap", ctr: 15 },
  { id: 198, name: "trapboom frezen", ctr: 15 },
  { id: 199, name: "balusters trap", ctr: 15 },
  { id: 200, name: "pvc trappen", ctr: 15 },
  { id: 201, name: "verven trapleuning", ctr: 15 },
  { id: 202, name: "massieve trap", ctr: 15 },
  { id: 203, name: "tekening trap", ctr: 15 },
  { id: 204, name: "eikenhout trap", ctr: 15 },
  { id: 205, name: "monumentale trap", ctr: 15 },
  { id: 206, name: "trapstoffering", ctr: 15 },
  { id: 207, name: "zolder trapladder", ctr: 15 },
  { id: 208, name: "trapbaluster", ctr: 15 },
  { id: 209, name: "trap herstellen", ctr: 15 },
  { id: 210, name: "kast trapkast", ctr: 15 },
  { id: 211, name: "luie trap", ctr: 15 },
  { id: 212, name: "hoekprofiel trap", ctr: 15 },
  { id: 213, name: "trap blank", ctr: 15 },
  { id: 214, name: "verven trap", ctr: 15 },
  { id: 215, name: "trap industrieel", ctr: 15 },
  { id: 216, name: "stalen trap detail", ctr: 15 },
  { id: 217, name: "trapmaantjes plaatsen", ctr: 15 },
  { id: 218, name: "rvs strip trap", ctr: 15 },
  { id: 219, name: "wanddecoratie trapgat", ctr: 15 },
  { id: 220, name: "lambrisering trap", ctr: 15 },
  { id: 221, name: "trap met spotjes", ctr: 15 },
  { id: 222, name: "trapspin", ctr: 15 },
  { id: 223, name: "boucle tapijt trap", ctr: 15 },
  { id: 224, name: "open traphal", ctr: 15 },
  { id: 225, name: "m trap", ctr: 15 },
  { id: 226, name: "glazen trap", ctr: 15 },
  { id: 227, name: "stalen trap dwg", ctr: 15 },
  { id: 228, name: "trap ikea", ctr: 15 },
  { id: 229, name: "traphekjes open trap", ctr: 14 },
  { id: 230, name: "spijlen trap", ctr: 14 },
  { id: 231, name: "trapleuning smalle trap", ctr: 14 },
  { id: 232, name: "reling trap", ctr: 14 },
  { id: 233, name: "open trap laminaat", ctr: 14 },
  { id: 234, name: "laminaat op trap", ctr: 14 },
  { id: 235, name: "wellat trap", ctr: 14 },
  { id: 236, name: "trapleuning spiltrap", ctr: 14 },
  { id: 237, name: "trap strippen", ctr: 14 },
  { id: 238, name: "trap wiki", ctr: 14 },
  { id: 239, name: "kast in kast trapkast", ctr: 14 },
  { id: 240, name: "tapijt open trap", ctr: 14 },
  { id: 241, name: "tapijttegels trap", ctr: 14 },
  { id: 242, name: "antisliplaag trap", ctr: 14 },
  { id: 243, name: "exclusieve trappen", ctr: 14 },
  { id: 244, name: "trapleuning inmeten", ctr: 14 },
  { id: 245, name: "stalen z trap", ctr: 14 },
  { id: 246, name: "speciale trapverf", ctr: 14 },
  { id: 247, name: "trapleuning houder", ctr: 14 },
  { id: 248, name: "steile trap", ctr: 14 },
  { id: 249, name: "trap pimpen", ctr: 14 },
  { id: 250, name: "vast trap", ctr: 14 },
  { id: 251, name: "sogem trap", ctr: 14 },
  { id: 252, name: "trap simulator", ctr: 14 },
  { id: 253, name: "cm trap", ctr: 14 },
  { id: 254, name: "traphek open trap", ctr: 14 },
  { id: 255, name: "kast trapvorm", ctr: 13 },
  { id: 256, name: "trap balustrade", ctr: 13 },
  { id: 257, name: "luik trap", ctr: 13 },
  { id: 258, name: "open trap tapijt", ctr: 13 },
  { id: 259, name: "schroef trapleuning", ctr: 13 },
  { id: 260, name: "traphek dubbele trap", ctr: 13 },
  { id: 261, name: "monteren trapleuning", ctr: 13 },
  { id: 262, name: "trapleuning hubo", ctr: 13 },
  { id: 263, name: "trappen modern", ctr: 13 },
  { id: 264, name: "trapleuning los", ctr: 13 },
  { id: 265, name: "strips trap", ctr: 13 },
  { id: 266, name: "slijtvaste trapverf", ctr: 13 },
  { id: 267, name: "plinten trap", ctr: 13 },
  { id: 268, name: "trap specialist", ctr: 13 },
  { id: 269, name: "trap staal", ctr: 13 },
  { id: 270, name: "trapladder met leuning", ctr: 13 },
  { id: 271, name: "dikte trapleuning", ctr: 13 },
  { id: 272, name: "trap wrappen", ctr: 13 },
  { id: 273, name: "afsluiten trapgat", ctr: 13 },
  { id: 274, name: "indeling trapkast", ctr: 13 },
  { id: 275, name: "oneindige trap", ctr: 13 },
  { id: 276, name: "trap railing", ctr: 13 },
  { id: 277, name: "open trap kraakt", ctr: 13 },
  { id: 278, name: "overtreffende trap", ctr: 13 },
  { id: 279, name: "hotec trappen", ctr: 13 },
  { id: 280, name: "trap ontvetten", ctr: 13 },
  { id: 281, name: "trapleuninghouders", ctr: 13 },
  { id: 282, name: "trap lijm", ctr: 13 },
  { id: 283, name: "neuslat trap", ctr: 13 },
  { id: 284, name: "dakterras trap", ctr: 12 },
  { id: 285, name: "smalle trappen", ctr: 12 },
  { id: 286, name: "beuken trapleuning", ctr: 12 },
  { id: 287, name: "trap lambrisering", ctr: 12 },
  { id: 288, name: "trap bekisten", ctr: 12 },
  { id: 289, name: "open trap traphek", ctr: 12 },
  { id: 290, name: "bruine trap", ctr: 12 },
  { id: 291, name: "trapmatten wit", ctr: 12 },
  { id: 292, name: "trapmatten leggen", ctr: 12 },
  { id: 293, name: "rvs trappen", ctr: 12 },
  { id: 294, name: "m295 trap", ctr: 12 },
  { id: 296, name: "trapelementen", ctr: 12 },
  { id: 297, name: "trapgat maken", ctr: 12 },
  { id: 298, name: "trappen roeselare", ctr: 12 },
  { id: 299, name: "rvs antislip trap", ctr: 12 },
  { id: 300, name: "trap laminaat leggen", ctr: 12 },
  { id: 301, name: "enci trap", ctr: 12 },
  { id: 302, name: "wanddecoratie trap", ctr: 12 },
  { id: 303, name: "trap iroko", ctr: 12 },
  { id: 304, name: "balustrade hek trap", ctr: 12 },
  { id: 305, name: "behangen boven trapgat", ctr: 12 },
  { id: 306, name: "luie trappen", ctr: 11 },
  { id: 307, name: "steektrappen", ctr: 11 },
  { id: 308, name: "trap vuren", ctr: 11 },
  { id: 309, name: "eestairs trappen", ctr: 11 },
  { id: 310, name: "trapmaantjes leggen", ctr: 11 },
  { id: 311, name: "spots trap", ctr: 11 },
  { id: 312, name: "trap vernis", ctr: 11 },
  { id: 313, name: "traprenovatie open trap", ctr: 11 },
  { id: 314, name: "afmeting trapgat bepalen", ctr: 11 },
  { id: 315, name: "antislipstrip trappen", ctr: 11 },
  { id: 316, name: "trapleuning", ctr: 11 },
  { id: 317, name: "iroko trap", ctr: 11 },
  { id: 318, name: "trap profiel", ctr: 11 },
  { id: 319, name: "histor trap verf", ctr: 11 },
  { id: 320, name: "helix trap", ctr: 11 },
  { id: 321, name: "trap elementen", ctr: 11 },
  { id: 322, name: "trap kleed", ctr: 11 },
  { id: 323, name: "breedte trap", ctr: 11 },
  { id: 324, name: "laminaat hoekprofiel trap", ctr: 11 },
  { id: 325, name: "trap toebehoren", ctr: 11 },
  { id: 326, name: "trapleuning beuken", ctr: 11 },
  { id: 327, name: "trappen oostende", ctr: 11 },
  { id: 328, name: "jewe trap", ctr: 11 },
  { id: 329, name: "trapkast indeling", ctr: 11 },
  { id: 330, name: "trapleuning lakken", ctr: 11 },
  { id: 331, name: "armleuning trap", ctr: 11 },
  { id: 332, name: "open trap wit", ctr: 11 },
  { id: 333, name: "helling trap berekenen", ctr: 11 },
  { id: 334, name: "trapmatten plaatsen", ctr: 11 },
  { id: 335, name: "trap overzet", ctr: 11 },
  { id: 336, name: "step up trap", ctr: 11 },
  { id: 337, name: "sisal tapijt trap", ctr: 11 },
  { id: 338, name: "open z trap", ctr: 11 },
  { id: 339, name: "gebogen trapleuning", ctr: 11 },
  { id: 340, name: "trap 341d", ctr: 10 },
  { id: 342, name: "takken trapleuning", ctr: 10 },
  { id: 343, name: "strip trap", ctr: 10 },
  { id: 344, name: "vlonder trap", ctr: 10 },
  { id: 345, name: "trapkast maken", ctr: 10 },
  { id: 346, name: "krakende open trap verhelpen", ctr: 10 },
  { id: 347, name: "anti slip mat trap", ctr: 10 },
  { id: 348, name: "trapleuning plakt", ctr: 10 },
  { id: 349, name: "deco trap", ctr: 10 },
  { id: 350, name: "trap led strip", ctr: 10 },
  { id: 351, name: "trap eikenhout", ctr: 10 },
  { id: 352, name: "lijmresten trap", ctr: 10 },
  { id: 353, name: "trap online", ctr: 10 },
  { id: 354, name: "trapleuning bocht", ctr: 10 },
  { id: 355, name: "pvc trap karwei", ctr: 10 },
  { id: 356, name: "trap met lades", ctr: 10 },
  { id: 357, name: "trap vernis antislip", ctr: 10 },
  { id: 358, name: "decoratie trap", ctr: 10 },
  { id: 359, name: "trapboom bevestiging", ctr: 10 },
  { id: 360, name: "trappen zaandam", ctr: 10 },
  { id: 361, name: "grind trap", ctr: 10 },
  { id: 362, name: "lijmverwijderaar trap", ctr: 10 },
  { id: 363, name: "staande trapleuning", ctr: 10 },
  { id: 364, name: "trapmaantjes", ctr: 9 },
  { id: 365, name: "trapmat micostep", ctr: 9 },
  { id: 366, name: "trap antislip", ctr: 9 },
  { id: 367, name: "antieke trap", ctr: 9 },
  { id: 368, name: "trap 369", ctr: 9 },
  { id: 370, name: "trap verven", ctr: 9 },
  { id: 371, name: "trap 372", ctr: 9 },
  { id: 373, name: "trap eik", ctr: 9 },
  { id: 374, name: "trap in living", ctr: 9 },
  { id: 375, name: "plak pvc trap", ctr: 9 },
  { id: 376, name: "half open trap", ctr: 9 },
  { id: 377, name: "trapleuning houders", ctr: 9 },
  { id: 378, name: "trapt 379", ctr: 8 },
  { id: 380, name: "trap breedte", ctr: 8 },
  { id: 381, name: "rvs trap balustrade", ctr: 8 },
  { id: 382, name: "betegelen trap", ctr: 8 },
  { id: 383, name: "stalen trap wit", ctr: 8 },
  { id: 384, name: "traphekje trapleuning", ctr: 8 },
  { id: 385, name: "stenen trap", ctr: 8 },
  { id: 386, name: "lak trapleuning", ctr: 8 },
  { id: 387, name: "trapleuning wit verven", ctr: 8 },
  { id: 388, name: "trap lamineren", ctr: 8 },
  { id: 389, name: "laminaat trapprofiel", ctr: 7 },
  { id: 390, name: "de vries trappen", ctr: 7 },
  { id: 391, name: "trap merbau", ctr: 7 },
  { id: 392, name: "zolder trapgat afsluiten", ctr: 7 },
  { id: 393, name: "trapkast laten maken", ctr: 7 },
  { id: 394, name: "trap tapijtjes", ctr: 7 },
  { id: 395, name: "karwei trapleuning", ctr: 7 },
  { id: 396, name: "led strip langs trap", ctr: 7 },
  { id: 397, name: "trap eiken", ctr: 7 },
  { id: 398, name: "balustrade trapgat", ctr: 7 },
  { id: 399, name: "overgang laminaat trap", ctr: 7 },
  { id: 400, name: "trap laminaat karwei", ctr: 7 },
  { id: 401, name: "trapleuning eikenhout", ctr: 7 },
  { id: 402, name: "hek trap", ctr: 6 },
  { id: 403, name: "trapleuning buigen", ctr: 6 },
  { id: 404, name: "trapleuning meten", ctr: 6 },
  { id: 405, name: "traploper voor open trap", ctr: 6 },
  { id: 406, name: "histor trapverf ervaring", ctr: 6 },
  { id: 407, name: "traphekje bij open trap", ctr: 6 },
  { id: 408, name: "pg trappen", ctr: 6 },
  { id: 409, name: "trap frezen antislip", ctr: 6 },
]

function Editor() {
  const [rows, setRows] = React.useState(_rows)
  const [selectionModel, setSelectionModel] = React.useState([])
  const columns = [
    { field: "name", headerName: "Keyword", width: 250 },
    { field: "ctr", headerName: "CTR", width: 250 },
    {
      field: "delete",
      width: 25,
      sortable: false,
      disableColumnMenu: false,
      renderHeader: () => {
        return (
          <IconButton
            onClick={() => {
              const selectedIDs = new Set(selectionModel)
              // you can call an API to delete the selected IDs
              // and get the latest results after the deletion
              // then call setRows() to update the data locally here
              setRows((r) => r.filter((x) => !selectedIDs.has(x.id)))
            }}
          >
            <FontAwesomeIcon icon={faRemove} size="xs" />{" "}
          </IconButton>
        )
      },
    },
  ]

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      checkboxSelection
      onSelectionModelChange={(ids) => {
        setSelectionModel(ids)
      }}
    />
  )
}

export default Editor
