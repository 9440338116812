import { memo, useMemo, useEffect, useState } from "react";
import { useNodes, useEdges, useUpdateNodeInternals } from "react-flow-renderer";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { keyframes } from "styled-components";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

import withRouter from "../../hocs/withRouter";
import Enrichment from "../visuals/enrichment.svg"
import Clustering from "../visuals/clustering.svg"
import AutoCodeEditor from "../inputs/AutoCodeEditor"
import AutoCodeActionHandle from "../handles/AutoCodeActionHandle";
import CircularProgress from '@mui/material/CircularProgress';

import CodeIcon from '@mui/icons-material/Code';
import MessageIcon from '@mui/icons-material/Message';

import { tweakAutoAction } from "../../redux/slices/algorithm.js";
import { debugAutoAction } from "../../redux/slices/algorithm.js";
import { startAutoAction } from "../../redux/slices/algorithm.js";
import { resumeAutoAction } from "../../redux/slices/algorithm.js";
import { editConverstation } from "../../redux/slices/algorithm.js";
import { saveActionTimeout } from "../../redux/slices/algorithm.js";
import { addMessageToAction } from "../../redux/slices/algorithm.js";
import { completeAutoAction } from "../../redux/slices/algorithm.js";
import { saveActionMaxRetries } from "../../redux/slices/algorithm.js";
import { deleteAlgorithmAction } from "../../redux/slices/algorithm.js";
import { inspectAutoActionFiles } from "../../redux/slices/algorithm.js";


function AutoCodeAction({ id, data, params, connecting, actionDrawerOpen, handleSetActionDrawerOpen, selectedHandle, runOnly }) {
  const dispatch = useDispatch();
  const actions = useNodes();
  const connectors = useEdges();
  const updateNodeInternals = useUpdateNodeInternals();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => updateNodeInternals(id), [connectors]);

  const { active, status, building, messages } = data;
  const { inputs, outputs } = data || [];
  const algorithmId = params.id;

  const [hover, setHover] = useState(false);

  const saveTimeout = (timeout) => dispatch(saveActionTimeout(algorithmId, id, timeout, building, status));
  const saveMaxRetries = (status, maxRetries, currentRetries, building) => dispatch(saveActionMaxRetries(algorithmId, id, status, maxRetries, currentRetries, building));

  const handleCloseAutoCodeEditor = () => handleSetActionDrawerOpen(false);
  const handleOpenAutoCodeEditor = () => handleSetActionDrawerOpen(id);

  const showRunOnlyToast = () => {
    enqueueSnackbar('Add your OpenAI API key first', { variant: 'warning' });
  }

  const sendMessage = (message) => {
    if (runOnly) showRunOnlyToast();
    else dispatch(addMessageToAction(algorithmId, id, message));
  }
  const deleteAction = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(deleteAlgorithmAction(algorithmId, id));
  }
  const tweakAutomationTask = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(tweakAutoAction(algorithmId, id));
  }
  const startAutomationTask = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(startAutoAction(algorithmId, id));
  }
  const debugAutomationTask = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(debugAutoAction(algorithmId, id));
  }
  const completeAutomationTask = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(completeAutoAction(algorithmId, id));
  }
  const inspectFiles = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(inspectAutoActionFiles(algorithmId, id));
  }
  const handleEditConversation = (index) => {
    if (runOnly) showRunOnlyToast();
    else dispatch(editConverstation(algorithmId, id, index));
  }
  const resumeAutomationTask = () => {
    if (runOnly) showRunOnlyToast();
    else dispatch(resumeAutoAction(algorithmId, id));
  }

  const buildingAnimation = keyframes`
  0% { border-color: rgba(0, 162, 44, 1) }
  50% { border-color: rgba(0, 162, 44, 0) }
  80% { border-color: rgba(0, 162, 44, 1) }
`;

const buildingIconAnimation = keyframes`
0% { color: rgba(0, 162, 44, 1) }
50% { color: rgba(0, 162, 44, 0) }
80% { color: rgba(0, 162, 44, 1) }
`;

const buildingHeadingAnimation = keyframes`
  0% { color: rgba(255, 255, 255, 1) }
  50% { color: rgba(255, 255, 255, 0) }
  80% { color: rgba(255, 255, 255, 1) }
`;

  const errorAnimation = keyframes`
  0% { border-color: rgba(255, 0, 0, 1) }
  50% { border-color: rgba(255, 0, 0, 0) }
  90% { border-color: rgba(255, 0, 0, 1) }
`;

  const inputHandles = useMemo(() => {
    let inputsWithY = [];
    for (const inputId in inputs) {
      const input = inputs[inputId];
      // Get connector that are connected to this input
      const inputConnector = connectors.find(x => x.targetHandle === inputId);
      if (inputConnector) {
        // Get the action that is connected to this input
        const inputAction = actions.find(x => x.id === inputConnector.source);
        // Get the y position of the action that is connected to this input
        const inputActionY = inputAction.position.y;
        // Add the input to the list of inputs with y position
        inputsWithY.push({ inputActionY, inputId, input });
      } else {
        // Add the input to the list of inputs with y position
        inputsWithY.push({ inputActionY: 10000000, inputId, input });
      }
    }
    // Sort the inputs by y position
    inputsWithY.sort((a, b) => a.inputActionY - b.inputActionY);

    let inputHandles = [];
    for (const input of inputsWithY) {
      const inputId = input.inputId;
      const isConnected = connectors.some(x => x.targetHandle === inputId);
      const label = input.input.name ? input.input.name + "." + input.input.extension : '';
      const dataType = input.input.data_type;
      inputHandles.push(
        <AutoCodeActionHandle active={selectedHandle && selectedHandle.handleId === inputId} type="target" actionId={id} connecting={connecting} id={inputId} label={label}
        isConnected={isConnected} dataType={dataType} />
      );
    }
    return inputHandles;
  }, [connectors]);

  const outputHandles = useMemo(() => {
    let outputsWithY = [];
    for (const outputId in outputs) {
      const output = outputs[outputId];
      // Get connector that are connected to this input
      const outputConnector = connectors.find(x => x.sourceHandle === outputId);
      if (outputConnector) {
        // Get the action that is connected to this input
        const outputAction = actions.find(x => x.id === outputConnector.target);
        // Get the y position of the action that is connected to this input
        const outputActionY = outputAction.position.y;

        // Add the input to the list of inputs with y position
        outputsWithY.push({ outputActionY, outputId, output });
      } else {
        // Add the input to the list of inputs with y position
        outputsWithY.push({ outputActionY: 10000000, outputId, output });
      }
    }
    // Sort the inputs by y position
    outputsWithY.sort((a, b) => a.outputActionY - b.outputActionY);

    let outputHandles = [];
    for (const output of outputsWithY) {
      const outputId = output.outputId;
      const isConnected = connectors.some(x => x.sourceHandle === outputId);
      const label = output.output.name ? output.output.name + "." + output.output.extension : '';
      const dataType = output.output.data_type;
      outputHandles.push(
        <AutoCodeActionHandle active={selectedHandle && selectedHandle.handleId === outputId} type="source" id={outputId} actionId={id} connecting={connecting} label={label}
        isConnected={isConnected} dataType={dataType}/>
      );
    }
    return outputHandles;
  }, [connectors]);

  const statusBorder = () => {
    if (status === 'error' || status === 'error') return "solid 6px red";
    else if ( status == 'building' || status == 'testing') return "solid 6px #00a22c";
    else return "solid 6px #052319";
  }

  const pulseAnimation = () => {
    if (status === 'error' || status === 'error') return errorAnimation;
    else if ( status == 'building'|| status == 'testing') return buildingAnimation;
    else return "none";
  }

  return (
    <>
    <AutoCodeEditor open={actionDrawerOpen === id} actionData={data} actionId={id} messages={messages} sendMessage={sendMessage} 
    tweakAutomationTask={tweakAutomationTask} debugAutomationTask={debugAutomationTask} startAutomationTask={startAutomationTask} 
    completeAutomationTask={completeAutomationTask} inspectFiles={inspectFiles} handleCloseAutoCodeEditor={handleCloseAutoCodeEditor} 
    saveMaxRetries={saveMaxRetries} saveTimeout={saveTimeout} editConversation={handleEditConversation} resumeAutomationTask={resumeAutomationTask} runOnly={runOnly} />
    <div className="auto-action" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
    style={{ zIndex: '10000 !important', position: 'relative', borderLeft: statusBorder(), backgroundColor: hover ? '#04251a' : '#042319',
      animation: status == 'building' || status == 'testing' ? pulseAnimation() + " 3s ease-out infinite" : null, 
       }}>
      <h3 onClick={handleOpenAutoCodeEditor} style={{ 
        fontFamily: "Outfit", fontSize: 18, fontWeight: 500, lineHeight: "18px", letterSpacing: "0.5px", paddingTop: 23, paddingBottom: 21, paddingLeft: 18, paddingRight: 30, color: "#ffffff", 
        overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", width: '284px', cursor: 'pointer',
        animation: status == 'building' || status == 'testing' ? buildingHeadingAnimation + " 3s ease-out infinite" : null,
        }}>
          <CodeIcon sx={{ color: 'rgb(0, 162, 44)', marginRight: '13px', marginBottom: '-7px', width: '29px', height: '29px', 
          animation: status == 'building' || status == 'testing' ? buildingIconAnimation + " 3s ease-out infinite" : null
          }} />
        { data.label ? data.label : 'Automation' }
      </h3>
        <IconButton aria-label="close" onClick={deleteAction} size="large" disableRipple={true}
          sx={{ position: 'absolute', right: 7, top: 7, color: '#438555', display: hover ? 'block' : 'none' }} >
          <CloseIcon />
        </IconButton>
      <div className="wrapper" onClick={handleOpenAutoCodeEditor} style={{ cursor: 'pointer' }}>
        <div style={{
          width: 40,
          opacity: active ? "30%" : "100%",
        }}>{ inputHandles }</div>
        <div style={{
            backgroundImage: `url(${Enrichment})`,
            backgroundPosition: "top center",
            backgroundSize: "100%",
            opacity: status === 'completed' ? (hover ? "100%" : "90%") : (hover ? "57%" : "27%"),
            transition: "opacity 0.3s ease 0s",
            animation: status === 'running' ? "pulse 2s cubic-bezier(0.5, 0.2, 0.3, 1.0) infinite" : "none",
            width: 242,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            minHeight: 85,
        }}>
        </div>
          <div style={{ position: 'absolute', bottom: 20, right: 25 }}>
          { status === 'llm_message' ? <MessageIcon sx={{ color: '#00a22c', fontSize: '23px' }} /> : null }
          { status === 'generating_llm_message' || status === 'executing_code' ? <CircularProgress sx={{ mb: 0, color: '#00dd3c' }} size="1.3rem" /> : null }
          </div>


        <div style={{
          width: 0,
          backgroundColor: status === 'completed' ? "#054b1b" : "#042e19",
          boxShadow: "0px 0px 10.5128px 4.62562px rgba(0, 0, 0, 0.1)",
          paddingTop: 3,
        }}>{ outputHandles }</div>
      </div>
    </div>
    </>
  )
}

const enhance = compose(withRouter);
export default enhance(memo(AutoCodeAction));