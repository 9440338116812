import { useState, useCallback, useEffect, useMemo, createElement, useRef } from 'react';
import Dagre from '@dagrejs/dagre';

import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import ReactFlow, { applyNodeChanges, useReactFlow } from 'react-flow-renderer';
import { useSnackbar } from 'notistack';
import { Container } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import Page from '../components/Page';
import withRouter from '../hocs/withRouter';
import LoadingScreen from '../components/LoadingScreen';
import FileAction from './actions/FileAction';
import PromptAction from './actions/AutoCodeAction';
import Connector from './connectors/Connector';
import ContextDrawer from './context/ContextDrawer';
import AlgorithmEditor from './inputs/AlgorithmEditor';


import useAuth from '../hooks/useAuth';
import withAuth from '../hocs/withAuth';

import { duplicateFirstoreAlgorithm } from '../redux/slices/algorithm';
import { completeAlgorithm } from '../redux/slices/algorithm';
import { addMessageToAlgorithm, updateFirestoreAlgorithm } from '../redux/slices/algorithm';
import { saveConversationTimeout } from '../redux/slices/algorithm';

import './styles.css';
import { set } from 'lodash';


const withProps = (WrappedComponent, additionalProps = {}) => {
  return (props) => { return createElement(WrappedComponent, { ...props, ...additionalProps }) };
};

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges) => {
  g.setGraph({ 
    rankdir: 'LR',
    // ranker: 'longest-path',
    // acyclicer: 'greedy',
    align: 'DL',
    ranksep: 200,
    nodesep: 25,
    edgesep: 0,
  });

  // edges.forEach((edge) => g.setEdge(edge.source, edge.target, { label: " ", labelpos: 'C', width: 10, height: 40 }));
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));

  nodes.forEach((node) => {
    const width = node.type === 'auto_code' ? 283 : 325; // Default width
    const height = node.type === 'auto_code' ? 400 : 110; // Default height
    g.setNode(node.id, { ...node, width, height });
  });

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);
      const newNode = JSON.parse(JSON.stringify(node));

      // The nodes are positioned in the center, so we need to move them to the top left
      // newNode.position = { x: x - newNode.width / 2, y: y - newNode.height / 2 };
      // newNode.position = { x: x - newNode.width / 2, y };
      newNode.position = { x, y };
      return newNode;
    }),
    edges,
  };
};


const Algorithm = ({ algorithm, conversation, updateFirestoreAlgorithm, params, userData }) => {
  const { user } = useAuth();
  const dragRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reactFlowInstance = useReactFlow();
  const { enqueueSnackbar } = useSnackbar();
  
  const algorithmId = params.id;
  // const runOnly = userData && userData.keys && userData.keys.openai && userData.keys.openai.enc_key ? false : true;
  const runOnly = false;
  const viewOnly = algorithm?.user_id !== user.id;
  const isTemplate = algorithm ? algorithm.user_id === 'template' : true;
  const defaultConnectorOptions = { type: "connector", zIndex: 10000 };

  const [target, setTarget] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [selectedHandle, setSelectedHandle] = useState(null);
  const [actionDrawerOpen, setActionDrawerOpen] = useState(false);
  const [builderOpen, setBuilderOpen] = useState(true);
  const [actions, setActions] = useState(algorithm ? algorithm.actions || [] : []);
  const [connectors, setConnectors] = useState(algorithm ? algorithm.connectors || [] : []);
  const [algorithmUserId, setAlgorithmUserId] = useState(algorithm ? algorithm.user_id : null);
  const [isBuilding, setIsBuilding] = useState(false);

  const onNodeDragStart = (evt, node) => dragRef.current = node;
  const onNodeDragStop = (evt, node) => {};

  const handleSetActionDrawerOpen = (id) => { setActionDrawerOpen(id) }
  const enterOpenAIKey = () => navigate('/user/account');

  const duplicateAlgorithm = () => dispatch(duplicateFirstoreAlgorithm(algorithmId, user.id));
  const sendMessage = (message) => dispatch(addMessageToAlgorithm(algorithmId, message));
  const complete = () => dispatch(completeAlgorithm(algorithmId));
  const openBuilder = () => setBuilderOpen(true);
  const closeBuilder = () => setBuilderOpen(false);
  const saveTimeout = (timeout) => dispatch(saveConversationTimeout(algorithmId, timeout));

  const onConnectStop = useCallback(() => setConnecting(false));

  useEffect(() => {
    if(algorithm) {
      if (algorithm.actions.length === 0) {
        setActions([]);
        setConnectors([]);
        setAlgorithmUserId(algorithm.user_id || null);
        setIsBuilding(false);
      } else {
        const layouted = getLayoutedElements(algorithm.actions || [], algorithm.connectors || []);
        setActions([...layouted.nodes]);
        setConnectors([...layouted.edges]);
        setAlgorithmUserId(algorithm.user_id || null);

        // Check if there is an action with the status building or testing
        const building = algorithm.actions.find((action) => action.data.status === 'building');
        const testing = algorithm.actions.find((action) => action.data.status === 'testing');
        setIsBuilding(building || testing ? true : false);

      }

      // setActions(algorithm.actions || []);
      // setConnectors(algorithm.connectors || []);
      // setAlgorithmUserId(algorithm.user_id || null);

    } else {
      setActions([]);
      setConnectors([]);
      setAlgorithmUserId(null);
    }
  }, [algorithm, algorithmId]);

  useEffect(() => {
    // Wait a second for the instance to be created
    setTimeout(() => {
      if(reactFlowInstance) reactFlowInstance.fitView({ maxZoom: 0.6, minZoom: 0.1 });
    }, 50);
  }, [algorithmId, builderOpen]);



  const onNodeDrag = (evt, node) => {
    // calculate the center point of the node from position and dimensions
    const centerX = node.positionAbsolute.x + node.width / 2;
    const centerY = node.positionAbsolute.y + node.height / 2;

    // find a node where the center point is inside
    const targetNode = actions.find(
      (n) =>
        n.id !== node.id &&
        centerX > n.positionAbsolute.x &&
        centerX < n.positionAbsolute.x + n.width &&
        centerY > n.positionAbsolute.y &&
        centerY < n.positionAbsolute.y + n.height 
    );
    setTarget(targetNode);
  };

  const actionTypes = useMemo(() => ({
    file: withProps(FileAction, { connecting, selectedHandle, algorithmUserId, runOnly }),
    auto_code: withProps(PromptAction, { connecting, actionDrawerOpen, handleSetActionDrawerOpen, selectedHandle, runOnly }),
  }), [connecting, selectedHandle, actionDrawerOpen, algorithmUserId, runOnly]);

  const connectorTypes = useMemo(() => ({
    connector: withProps(Connector, { runOnly }),
  }), [runOnly]);


  const onActionChange = useCallback((changes) => {
    // Update positions while dragging
    setActions(applyNodeChanges(changes, actions));
    applyActionChanges(changes);
  });
  
  const onConnect = useCallback((connector) => {
    if (runOnly) return enqueueSnackbar('Add your OpenAI API key first', { variant: 'warning' });
    connector.id = uuidv4().replace(/-/g, '');
    // Update an action with id target to io_changed if action with target id status is building
    const updatedActions = actions.map((action) => {
      if (action.type === 'auto_code' && (action.id === connector.target || action.id === connector.source)) {
        return {...action, data: { ...action.data, status: 'io_changed' }} }
      return action;
    });
    updateFirestoreAlgorithm(algorithmId, {...algorithm, actions: updatedActions, connectors: [...connectors, connector]}, true, runOnly);
  });

  const onConnectStart = useCallback((_, params) => {
    if (selectedHandle) {
      if (params.handleType === 'source' && selectedHandle.handleType === 'target') {
        onConnect({ source: params.nodeId, sourceHandle: params.handleId, target: selectedHandle.nodeId, targetHandle: selectedHandle.handleId
        });
        setSelectedHandle(null);
      } else if (params.handleType === 'target' && selectedHandle.handleType === 'source'){
        onConnect({ source: selectedHandle.nodeId, sourceHandle: selectedHandle.handleId, target: params.nodeId, targetHandle: params.handleId });
        setSelectedHandle(null);
      } else {
        setSelectedHandle(null);
      }
    } else {
      setSelectedHandle({ 'nodeId' : params.nodeId, 'handleId' : params.handleId, 'handleType' : params.handleType })
    }
    setConnecting(params)
  });



  if(!algorithm) return <LoadingScreen />;

  const applyActionChanges = (changes) => {
    changes.forEach((change) => {
      if (changes[0].type === "position" && changes[0].dragging === false) {
        console.log('applyActionChanges');
        updateFirestoreAlgorithm( algorithmId, { ...algorithm, actions: actions, connectors: connectors }, false);
        setTarget(null);
        dragRef.current = null;
      }
    });
  }

  const addAction = (action) => {
    if (runOnly) enqueueSnackbar('Add your OpenAI API key first', { variant: 'warning' });
    else {
      updateFirestoreAlgorithm(algorithmId, { ...algorithm, actions: [...actions, action], connectors: connectors }, user.id, false);
      complete();
    }
  }



  return (
      <Page title={algorithm.name}>
        {/* <Container maxWidth='md'>
          <AlgorithmForm algorithm={algorithm} /> 
        </Container> */}
        <Container maxWidth='xl'>
          <div style={{ height: "calc(100vh)", width: builderOpen ? "calc(100vw - 800px)" : "calc(100vw)", position: "absolute", top: "0", left: "0", }}>
            <ReactFlow
              readOnly={isTemplate}
              nodes={actions} edges={connectors}
              nodeTypes={actionTypes} edgeTypes={connectorTypes}
              onNodesChange={onActionChange} 
              // onConnect={onConnect}
              // onConnectStart={onConnectStart} onConnectStop={onConnectStop}
              defaultEdgeOptions={defaultConnectorOptions}
              defaultPosition={[300, 200]} defaultZoom={0.5}
              fitViewOptions={{ maxZoom: 0.6}}
              minZoom={0.1} maxZoom={0.7}
              fitView={true}
              // connectOnClick={true}
              // snapGrid={[25, 25]} snapToGrid={true}
              // onNodeDragStart={onNodeDragStart}
              // onNodeDrag={onNodeDrag}
              // onNodeDragStop={onNodeDragStop}
              connectionLineStyle={{ stroke: "#03531c80", strokeOpacity: "50%", zIndex: -100, strokeWidth: 44 }}
              deleteKeyCode={null}
              // nodesDraggable={false}
              // nodesConnectable={false}
              // elementsSelectable={false}
              // nodesFocusable={false}
            >
            </ReactFlow>
          </div>
          {/* {!algorithm.hasOwnProperty("actions") || algorithm.actions.length === 0 ? (
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 14, color: 'rgb(208, 255, 221)' }}>
              Add an automation to get started
            </div>
          ) : null} */}
          {runOnly && !viewOnly ? (
            <div style={{ position: "absolute", zIndex: 10, bottom: "10%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 14, color: 'rgb(208, 255, 221)' }}>
              Algorithm is in run only mode. <a onClick={enterOpenAIKey} style={{ color: '#008e27', fontWeight: 500, cursor: 'pointer' }}>Add your OpenAI API key</a> to edit it.
            </div>
          ) : null}
          {viewOnly ? (
            <div style={{ position: "absolute", zIndex: 10, bottom: "10%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 14, color: 'rgb(208, 255, 221)' }}>
              Algorithm is in view only mode. <a onClick={duplicateAlgorithm} style={{ color: '#008e27', fontWeight: 500, cursor: 'pointer' }}>Duplicate</a> it to use it.
            </div>
        ) : null}
      </Container>
      <AlgorithmEditor conversation={conversation} open={builderOpen} runOnly={runOnly} close={closeBuilder}
        sendMessage={sendMessage} complete={complete} addAction={addAction} saveTimeout={saveTimeout} isBuilding={isBuilding} />
      {builderOpen ? null : (
        <IconButton onClick={openBuilder} variant="contained" sx={{
          pl: 2, pr: 2, pt: 1.2, pb: 1.2,
          zIndex: 10000,
          position: 'absolute',
          bottom: '40px',
          left: '40px',
          backgroundColor: '#008e27',
          borderRadius: '4px',
          boxShadow: 'none',
          fontSize: '13px',
          letterSpacing: '0.2px',
          fontWeight: 500,
          fontFamily: 'Outfit, sans-serif',
        }}>
          <AddIcon sx={{ fontSize: '14px' }} /> &nbsp;&nbsp;Open builder
        </IconButton>
      )}
      {/* <ContextDrawer algorithmId={algorithmId} viewingAlgorithm={user.id != algorithmUserId} addAction={addAction} context={isTemplate ? "template" : "algorithm"} /> */}
      </Page>
  )
}

const mapDispatchToProps = (dispatch) => ({
    updateFirestoreAlgorithm: (algorithmId, algorithm, run) => dispatch(updateFirestoreAlgorithm(algorithmId, algorithm, run)),
});

const parseAlgorithmToLists = (algorithm) => {
  if (!algorithm) return algorithm;
  const actions = Object.keys(algorithm.actions).length >= 0 ? Object.values(algorithm.actions) : [];
  const connectors = Object.keys(algorithm.actions).length >= 0 ? Object.values(algorithm.connectors) : [];
  const parsedAlgorithm = { ...algorithm, actions, connectors };
  return parsedAlgorithm;
}

const enhance = compose(
  withRouter,
  withAuth,
  firestoreConnect((props) => [
    { collection: 'algorithms', doc: props.params.id },
    { collection: 'users', doc: props.user?.id }
  ]),
  connect(({ firestore: { data } }, props) => ({
    algorithm: data.algorithms && data.algorithms[props.params.id] ? {
      ...parseAlgorithmToLists(data.algorithms[props.params.id])
    } : null,
    conversation: data.algorithms && data.algorithms[props.params.id] ? data.algorithms[props.params.id].conversation : null,
    userData: data.users && data.users[props.user?.id] ? data.users[props.user?.id] : true,
  }), mapDispatchToProps),
);

export default enhance(Algorithm);