import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useSelector, useDispatch } from "react-redux";
import { setInput, selectNodeValidationErrors } from "../redux/slices/keywordclustering.js";


function TextInput(props) {
  const text = useSelector((state) => state.keywordclustering.nodes.find(x => x.id === props.id).data.input);
  const validationErrors = useSelector((state) => selectNodeValidationErrors(state, props.id));

  const dispatch = useDispatch();

  const onChange = (event) => {
    dispatch(setInput({id: props.id, input: event.target.value}));
  };

  return (
    <>
      <TextField
        id="filled-textarea"
        label={props.label}
        error = {validationErrors.length > 0}
        placeholder={"Type or paste " + props.label.toLowerCase() + " here"}
        variant="filled"
        onChange={onChange}             
        sx={{ width: 400}}
        value={text}
        helperText={ validationErrors.length > 0 ? validationErrors[0].message : "" }
        className="data-hj-allow"
        autoFocus
      />
    </>
  )
}

export default TextInput
