import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { useAppDispatch } from '../redux/store'
import { runAlgo, selectRunPrice } from '../redux/slices/keywordclustering'
import { selectShowRunModal } from '../redux/slices/keywordclustering'
import useAuth from '../hooks/useAuth';

export function useRunAlgo(name) {
  const dispatch = useDispatch()
  const showRunModal = useSelector((state) => selectShowRunModal(state))
  const runPrice = useSelector((state) => selectRunPrice(state))
  const { user } = useAuth();

  useEffect(() => {
    if (showRunModal === true) { dispatch(runAlgo({dry_run: true, userId: user.id})) }
  }, [showRunModal, dispatch])

  // return the import data for the caller of the hook to use
  return { runPrice }
}