import * as React from "react"
import { useCallback, useState, useMemo } from "react"
import { Handle, Position } from "react-flow-renderer"
// import { Handle, Position } from "reactflow"
import { Drawer, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import Editor from './Editor.js';
import ListInput from './ListInput.js';
import DropdownInput from "./DropdownInput.js";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import OptionsInput from "./OptionsInput.js";
import TextInput from "./TextInput.js";

import { useSelector, useDispatch } from "react-redux";
import { setStatus, setInput, selectNodeValidationErrors } from "../redux/slices/keywordclustering.js";

import { useSnackbar } from 'notistack';

function ManualNode({ id, data }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.keywordclustering.nodes.find(x=> x.id === id).data.status);
  const input = useSelector((state) => state.keywordclustering.nodes.find(x => x.id === id).data.input) || "";
  const validationErrors = useSelector((state) => selectNodeValidationErrors(state, id));

  const handleClickOpen = () => { setOpen(true) };
  const handleClose = (value) => { setOpen(false) };

  const handlePlay = (event) => {
    if(validationErrors.length == 0) {
      dispatch(setStatus({id: id, status: !status}));
    } else {
      validationErrors.forEach(validationError => {
        enqueueSnackbar(validationError.message, {variant: "error"});
      });
    }
  }

  const editor = () => {
    if (data.type === "list") {
      return <ListInput id={id} label={data.label} />
    } else if(data.type === "dropdown") {
      return <DropdownInput id={id} label={data.label} options={data.options} />
    } else if(data.type === "text") {
      return <TextInput id={id} label={data.label} />
    } else if(data.type === "options") {
      return <OptionsInput id={id} label={data.label} options={data.options} />
    } else if (data.type == "editor") {
      return <Editor label={data.label} />
    }
  };

  const preview = () => {
    var displayInput = input;
    if (displayInput == "") {
      displayInput = "None"
    }
    if (data.type == "options") {
      // displayInput = displayInput.map(Number).sort((a, b) => a - b).join(", ");
    }
    if (data.type == "list") {
      // displayInput = displayInput.join(", ");
    }

    return (
        <input
          className="node-input"
          id={id}
          name="value"
          // placeholder={displayInput}
          value={displayInput}
          autocomplete="off"
          onClick={handleClickOpen}
          onKeyDown={(event) => {
            event.preventDefault();
          }}
        />
    )
  }

  return (
    <div className="manual-node" >
      <Dialog
        // selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        style={{ backgroundColor: '#01150d4f', }}
        PaperProps={{
          style: { borderRadius: 5 },
          // backdropFilter: 'blur(20px)',
          // bgcolor: '#032219a6',
        }}
      >
    
        <DialogTitle>{data.label} input
        
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <Box sx={{
          mt: 3,
          mr: 3,
          mb: 6,
          ml: 3,
        }}>
          {editor()}
        </Box>
      </Dialog>

      <h3>{data.label}</h3>
      <div className="manual-input">
        <Handle
          type="target"
          position={Position.Left}
          id="manual_input"
          onClick={handleClickOpen}
        />
        {preview()}
        <div className={status ? 'pause' : 'play'} id={id + "-play"} onClick={handlePlay}>
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id="manual_output"
          style={{
            opacity: status ? "100%" : "55%",
            transition: "opacity 0.7s ease 0.1s",
          }}
        />
      </div>
    </div>
  )
}

export default ManualNode