import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useSelector, useDispatch } from "react-redux";
import { setInput, selectNodeValidationErrors } from "../redux/slices/keywordclustering.js";


function DropdownInput(props) {
  const choice = useSelector((state) => state.keywordclustering.nodes.find(x => x.id === props.id).data.input);
  const validationErrors = useSelector((state) => selectNodeValidationErrors(state, props.id));
  const dispatch = useDispatch();

  const onChange = (event) => {
    dispatch(setInput({id: props.id, input: event.target.value}));
  };

  const renderOptions = props.options.map((option) =>
    <MenuItem value={option.value}>{option.name}</MenuItem>
  );

  return (
    <FormControl variant="filled">
      <InputLabel id="demo-simple-select-helper-label">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        error={validationErrors.length > 0}
        id="demo-simple-select-helper"
        value={choice}
        label={props.label}
        sx={{ width: 400 }}
        onChange={onChange}
        className="data-hj-allow"
        autoFocus
      >
        <MenuItem value=""><em>None</em></MenuItem>
        {renderOptions}
      </Select>
      <FormHelperText>{validationErrors.length > 0 ? validationErrors[0].message : ""}</FormHelperText>
    </FormControl>
  )
}

export default DropdownInput
