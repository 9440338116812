import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import MonacoEditor from 'react-monaco-editor';

import * as monaco from 'monaco-editor';
// import { parseTmTheme } from 'monaco-themes';

import myCustomTheme from './Merbivore Soft.json'

import Box from '@mui/material/Box';

const TextEditor = ({ file, setFile }) => {
  const [text, setText] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(myCustomTheme);
    monaco.editor.defineTheme('myCustomTheme', myCustomTheme);
    monaco.editor.setTheme('myCustomTheme');
  }, [file]);

  useEffect(() => {
    const readFile = async () => {
      if (file) {
        const content = await file.text();
        setText(content);
      }
    };

    readFile();
  }, [file]);

  // const saveFile = () => {
  //   const blob = new Blob([text], { type: 'text/plain' });
  //   const newFile = new File([blob], file.name, { type: 'text/plain', lastModified: new Date() });
  //   setFile(newFile);
  //   enqueueSnackbar('File saved', { variant: 'success' });
  // };

  const handleSetText = (newText) => {
    const blob = new Blob([newText], { type: 'text/plain' });
    const newFile = new File([blob], file.name, { type: 'text/plain', lastModified: new Date() });
    setFile(newFile);
  }

  const options = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: 'line',
    padding: {
      top: 30,
      bottom: 30,
    },
    minimap: {
      enabled: false,
    },
    suggest: {
      showWords: false,
      showCompletions: false,
      showSnippets: false,
      showMethods: false,
    },
    scrollbar: {
      width: 5,
      height: 5,
    },
  };

  return (
    <>
    {/* <Box sx={{ mb: 10}}> */}
      {/* <Button onClick={saveFile} style={{ float: 'right', fontWeight: 500, marginRight: 20, marginBottom: 20 }}
      startIcon={<AddIcon />}> Save file</Button> */}
      {/* </Box> */}
      <Box sx={{ mt: 5 }}>
      <MonacoEditor
        width="100%"
        height="calc(100vh - 600px)"
        language="plaintext"
        value={text}
        options={options}
        onChange={handleSetText}
      />
      </Box>
    </>
  );
};

export default TextEditor;