

import ReactFlow, { applyEdgeChanges, applyNodeChanges } from "react-flow-renderer"
// import ReactFlow, { applyEdgeChanges, applyNodeChanges } from "reactflow"

import { useSelector, useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import axiosInstance from "src/utils/axios.js";

import { setNodes, setEdges, closeRunModal, runAlgo } from "../redux/slices/keywordclustering.js";
import useAuth from '../hooks/useAuth';
import ManualNode from "./ManualNode.js"
import TransformNode from "./TransformNode"
import AddNodeEdge from "./AddNodeEdge.js"
import { useRunAlgo } from "../hooks/useRunAlgo";
import { useSnackbar } from 'notistack';

import { useState, useCallback } from 'react';

// import { getAlgorithm } from "../redux/slices/algorithm.js";

import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

// import "./styling.css"

import { PATH_DASHBOARD } from "src/routes/paths.js";

import { useNavigate } from 'react-router-dom' 
import { store } from "src/redux/store.js";

const rfStyle = {
}

const edgeOptions = {
  animated: true,
  type: "standard",
  labelShowBg: false,
  labelStyle: {
    fontFamily: "Outfit",
    letterSpacing: "0.4px",
    fontSize: "16px",
    fontWeight: "500",
  },
}

const nodeOptions = {
}

const edgeTypes = {
  standard: AddNodeEdge,
}

const nodeTypes = {
  manual: ManualNode,
  transform: TransformNode,
}

function Flow() {
  const dispatch = useDispatch();
  const initialNodes = useSelector((state) => state.keywordclustering.nodes);
  const initialEdges = useSelector((state) => state.keywordclustering.edges);
  const showRunModal = useSelector((state) => state.keywordclustering.showRunModal);
  const { user } = useAuth();
  const { runPrice } = useRunAlgo();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const handleClose = () => {
    dispatch(closeRunModal());
  };

  const handleRun = () => {
    dispatch(closeRunModal());
    enqueueSnackbar('Running algorithm', { variant: 'success' });
    dispatch(runAlgo({dry_run: false, userId: user.id}));
  };

  const handleBuyMoreCredits = () => {
    dispatch(closeRunModal());
    navigate(PATH_DASHBOARD.user.account);
  }

  const renderCancelButton = (
    <Button onClick={handleClose}>Cancel</Button>
  )

  const renderRunButton = (
    <Button
      disabled={runPrice === null || runPrice.sufficient_funds === false}
      onClick={ handleRun } variant="contained">
      Let's go!
    </Button>
  )

  const renderBuyMoreCreditsButton = () => {
    if (runPrice && runPrice.sufficient_funds === false) {
      return <Button onClick={handleBuyMoreCredits}>Buy more credits</Button>
    }
  }

  const renderRunModal = () => {
    return (
      <Dialog
        // selectedValue={selectedValue}
        open={showRunModal}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        style={{ backgroundColor: '#01150d4f', }}
        PaperProps={{ style: { borderRadius: 5 }}}
      >
        <DialogTitle>Run Algorithm?</DialogTitle>
        <Box sx={{ mt: 3, mr: 3, mb: 4, ml: 3 }}>
          <DialogContentText id="alert-dialog-description">
            This will take a few minutes.
            We will send the results to the specified email address. 
            Other algorithms can be started while this completes.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{ pt: 3, fontWeight: 600, color: '#D0FFDD' }}>
            Run cost = { runPrice?.credit_cost } credits
          </DialogContentText>
          <DialogActions>
            { renderCancelButton }
            { renderRunButton }
            { renderBuyMoreCreditsButton() }
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  const onInit = (reactFlowInstance) => {
    reactFlowInstance.setCenter(1200, 700);
    reactFlowInstance.zoomTo(0.4);
  }

  const handleGetAlgorithm = () => {
    console.log("doet iets");
    // dispatch(getAlgorithm("PkUCX6UIngIYKID6DzZR"));
  }

  return (
    <>
      <Button style={{position: "absolute", right: 100, bottom: 0, zIndex: 30}} onClick={handleGetAlgorithm}>Click here!</Button>
      {renderRunModal()}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        defaultNodeOptions={nodeOptions}
        defaultEdgeOptions={edgeOptions}
        edgeTypes={edgeTypes}
        // onConnect={onConnect}
        nodeTypes={nodeTypes}
        defaultPosition={[300, 200]}
        defaultZoom={0.4}
        // onInit={onInit}
        minZoom={0.1}
        maxZoom={0.7}
        snapGrid={[25, 25]}
        snapToGrid={true}
        // fitViewOptions={{ padding: 5 }}
       // fitView={true}
        // defaultViewport={{ x: 1200, y: 700, zoom: 0.4 }}
        style={rfStyle}
      >
        {/* <Background
          // color="#00b02e"
          color="#036a21"
          gap={50}
          size={1}
          /> */}
      </ReactFlow>
    </>
  )
}

export default Flow;