import { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import TemplateContext from './TemplateContext';
import AlgorithmContext from './AlgorithmContext';

import { duplicateFirstoreAlgorithm } from '../../redux/slices/algorithm';

import useAuth from '../../hooks/useAuth';


const ContextDrawer = ({ algorithmId, addAction, context, duplicateAlgorithm, runOnly, viewOnly }) => {
  const [hover, setHover] = useState(false);

  const { user } = useAuth();

  const onHoverEnter = () => setHover(true);
  const onHoverLeave = () => setHover(false);

  return (
    <Drawer
      open
      anchor="bottom"
      variant="persistent"
      onMouseEnter={onHoverEnter}
      onMouseLeave={onHoverLeave}
      PaperProps={{
        sx: {
          height: 85,
          borderTopStyle: 'none',
          bgcolor: '#031b13',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'columns', justifyContent: 'space-between', alignItems: 'center' }} pt={3} pr={4} pb={3} pl={5}>
        <Box >
          {context === 'algorithm' ? <AlgorithmContext hover={hover} addAction={addAction} /> : null}
          {context === 'template' ? <TemplateContext algorithmId={algorithmId} /> : null}
        </Box>
        <Box>
          {viewOnly ?
            (
              <Button variant="text" color="primary" size="small" sx={{ pl: 1.5, pr: 1.5, color: '#00a22c', textTransform: 'none' }} onClick={() => duplicateAlgorithm(algorithmId, user.id)}>
                <ContentCopyIcon sx={{ mr: 1, fontSize: '12px' }} /> {/* Icon with a right margin */}
                Duplicate algorithm
              </Button>
            ) :
            (
              <Button variant="text" color="primary" size="small" sx={{ pl: 1.5, pr: 1.5, color: '#d0ffdd', textTransform: 'none' }} onClick={() => duplicateAlgorithm(algorithmId, user.id)}>
                <ContentCopyIcon sx={{ mr: 1, fontSize: '12px' }} /> {/* Icon with a right margin */}
                Duplicate algorithm
              </Button>
            )
          }
        </Box>
      </Box>
    </Drawer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  duplicateAlgorithm: (id, userId) => dispatch(duplicateFirstoreAlgorithm(id, userId)),
});

const enhance = compose(
  connect(null, mapDispatchToProps),
);

// export default ContextDrawer;

export default enhance(ContextDrawer);