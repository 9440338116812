import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useSelector, useDispatch } from "react-redux";
import { setInput, selectNodeValidationErrors } from "../redux/slices/keywordclustering.js";


function ListInput(props) {
  const list = useSelector((state) => (state.keywordclustering.nodes.find(x => x.id === props.id)).data.input || []).join("\r\n");
  const validationErrors = useSelector((state) => selectNodeValidationErrors(state, props.id));
  const dispatch = useDispatch();

  const onChange = (event) => {
    const list = event.target.value.split(/\r?\n|\r|\n/g) 
    dispatch(setInput({id: props.id, input: list}));
  };

  return (
    <>
      <TextField
        id="filled-textarea"
        label={props.label}
        error={validationErrors.length > 0}
        placeholder={"Type or paste " + props.label.toLowerCase() + " here"}
        multiline
        variant="filled"
        onChange={onChange}             
        minRows="25"
        sx={{ width: 400}}
        value={list}
        helperText={ validationErrors.length > 0 ? validationErrors[0].message : "" }
        className="data-hj-allow"
        autoFocus
        autoComplete="off"
      />
    </>
  )
}

export default ListInput
