import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
// https://auth0.com/docs/libraries/auth0-single-page-app-sdk
import { Auth0Client } from '@auth0/auth0-spa-js';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import { AUTH0_API } from '../config';

import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';


import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { set } from 'lodash';

import firebase from 'firebase/app';

// ----------------------------------------------------------------------

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, user };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    // isInitialized: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

// const AuthContext = createContext({
export const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children, authTokenInvalidationTS }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [timestamp, setTimestamp] = useState(Date.now());

  // const navigate = useNavigate();
  // const onRedirectCallback = appState => {
    // Use the router's history module to replace the url
  //   navigate(appState?.returnTo || window.location.pathname, { replace: true });
  // };

  const exchangeAuth0TokenForFirebaseToken = async (auth0Token) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_GATEWAY_URL : 'http://localhost:8080';
    try {
      const response = await fetch(`${apiUrl}/executionengine/exchange-token`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${auth0Token}` },
      });

      const data = await response.json();
      return data.firebase_token;
    } catch (err) {
      console.log('Error exchanging token', err);
    }
  };

  const initialize = async() => {
    console.log('initialize')
    const location = window.location.origin;
    try {
      auth0Client = new Auth0Client({
        client_id: AUTH0_API.clientId,
        domain: AUTH0_API.domain,
        // redirect_uri: window.location.origin + '/',
        // redirect_uri: window.location.origin + '/',
        redirect_uri: location,
        // redirect_uri: 'https://app.algonext.ai/',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        // onRedirectCallback: onRedirectCallback,
      });

      if (window.location.search.includes("code=")) {
        console.log('code found');
        await auth0Client.handleRedirectCallback();
      }

      await auth0Client.checkSession();

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        try {

          user.token = await auth0Client.getTokenSilently({ ignoreCache: true });
        } catch (err) {
          console.log('Error getting token silently', err);
          if (err.error === 'login_required' || err.error === 'consent_required') {
            alert('Login failed. \nEnable third party cookies in your browser settings and/or do not user incognito/private browsing mode.')
            return;
          }
        }

        // Exchange auth0 token for firebase token
        const firebaseToken = await exchangeAuth0TokenForFirebaseToken(user.token);
        console.log('retrieved firebaseToken', firebaseToken);
        
        firebase.auth().signInWithCustomToken(firebaseToken)
          .then((userCredential) => {
            console.log('userCredential', userCredential);
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // You can implement error handling logic or display an error message to the user.

            console.log('errorCode', errorCode);
            console.log('errorMessage', errorMessage);
          });
        // firebase.auth().signInWithCustomToken(firebaseToken);
        user.firebase_token = firebaseToken;

        // Set local storage
        localStorage.setItem('accessToken', user.token);
        localStorage.setItem('firebaseToken', firebaseToken);

        setTimestamp(Date.now());

        console.log(user);

        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated, user },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated, user: null },
        });
      }
    } catch (err) {
      console.error(err);
      // dispatch({
      //   type: 'INITIALIZE',
      //   payload: { isAuthenticated: false, user: null },
      // });
    }
  };

  useEffect(() => {
      // Check if token is invalidated after login
      if (authTokenInvalidationTS && authTokenInvalidationTS > timestamp) {
        console.log('Current token invalidated');

        // Remove token from local storage
        localStorage.removeItem('accessToken');

        initialize();
      }
  }, [authTokenInvalidationTS]);

  useEffect(() => {
    initialize();
  }, []);

  // const onRedirectCallback = () => {
  //     auth0Client.handleRedirectCallback();
  // };

  const login = async (isSignup = false) => {
    // await auth0Client.loginWithPopup();

    try {
      await auth0Client.loginWithRedirect({
        screen_hint: isSignup ? 'signup' : 'login',
        authorizationParams: {
          // redirect_uri: 'https://app.algonext.ai/',
          // redirect_uri: window.location.origin + '/'
        }
      });

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {

        const user = await auth0Client.getUser();
        user.token = await auth0Client.getTokenSilently({ ignoreCache: true });

        // Exchange auth0 token for firebase token
        const firebaseToken = await exchangeAuth0TokenForFirebaseToken(user.token);
        console.log('retrieved firebaseToken', firebaseToken);

        firebase.auth().signInWithCustomToken(firebaseToken)
          .then((userCredential) => {
            console.log('userCredential', userCredential);
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // You can implement error handling logic or display an error message to the user.

            console.log('errorCode', errorCode);
            console.log('errorMessage', errorMessage);
          });


        // firebase.auth().signInWithCustomToken(firebaseToken);
        user.firebase_token = firebaseToken;

        // Set local storage
        localStorage.setItem('accessToken', user.token);
        localStorage.setItem('firebaseToken', firebaseToken);

        console.log(user);
        dispatch({ type: 'LOGIN', payload: { user } });
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'INITIALIZE',
        payload: { isAuthenticated: false, user: null },
      });
    }
  };

  const logout = async () => {
    // window.location.href = PATH_AUTH.login;
    const location = window.location.origin;

    // Remove token from local storage
    localStorage.removeItem('accessToken');

    // window.location.href = '/';
    await auth0Client.logout({
        returnTo: location,
        // async onRedirect(url) {
          // window.location.replace('/');
        // }
        // returnTo: window.location.origin + '/'
    }).then(() => {
      dispatch({ type: 'LOGOUT' });
    });
  }; 

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: {
          subscription_status: state?.user?.subscription_status,
          active_price_ids: state?.user?.active_price_ids,
          stripe_customer_id: state?.user?.stripe_customer_id,
          token: state?.user?.token,
          id: state?.user?.sub,
          photoURL: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.name,
          role: 'admin',
        },
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// export { AuthContext, AuthProvider };
// export { AuthProvider };

// Connect your component to Firestore and Redux
// export default compose(
//   firestoreConnect(() => [
//     { collection: 'users', doc: 'yourDocId' } // Replace with your collection and document ID
//   ]),
//   connect(mapStateToProps)
// )(AuthProvider);


const mapStateToProps = (state) => {
  return {
    // Accessing Firestore data using the user ID from the component's state (ownProps)
    authTokenInvalidationTS: state.firestore.data.users ? Object.values(state.firestore.data.users)?.[0]?.auth_token_invalidation_ts ?? null : null,
  };
};

const ConnectedAuthProvider = compose(
  firestoreConnect(props => props.userId ? [{ collection: 'users', doc: props.userId }] : []),
  connect(mapStateToProps)
)(AuthProvider);


export { ConnectedAuthProvider as AuthProvider }