import { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CodeIcon from '@mui/icons-material/Code';

// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ReplayIcon from '@mui/icons-material/Replay';
// import ScheduleIcon from '@mui/icons-material/Schedule';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Chat from './Chat'
// import Drawer from '@mui/material/Drawer';


const AutoCodeEditor = ({ open, actionId, actionData, sendMessage, tweakAutomationTask, debugAutomationTask, startAutomationTask, resumeAutomationTask, completeAutomationTask, inspectFiles, handleCloseAutoCodeEditor, saveTimeout, saveMaxRetries, editConversation, runOnly }) => {
  const { label, conversation, status, building } = actionData;
  // const { max_retries } = actionData || 3;
  // const { timeout } = actionData || 30;

  const [actionName, setActionName] = useState(label || '');
  const [wasNearBottom, setWasNearBottom] = useState(true);

  const boxRef = useRef(null);

  // useEffect(() => {
  //   const box = boxRef.current;
  //   if (!box) return;

  //   const checkIsNearBottom = () => {
  //     const tolerance = 20; // adjust as necessary
  //     return box.scrollHeight - box.scrollTop - box.clientHeight < tolerance;
  //   };

  //   // Check and save the state every time the user scrolls
  //   const onScroll = () => setWasNearBottom(checkIsNearBottom());
  //   box.addEventListener('scroll', onScroll);

  //   // Cleanup the event listener when the component unmounts
  //   return () => box.removeEventListener('scroll', onScroll);
  // }, []);

  // useEffect(() => {
  //   const box = boxRef.current;
  //   if (box && wasNearBottom) {
  //     box.scrollTop = box.scrollHeight;
  //   }
  // }, [conversation]);

  useEffect(() => setActionName(label), [label]);

  const handleClose = () => handleCloseAutoCodeEditor();
  const handleActionNameChange = (event) => setActionName(event.target.value);
  // const handleMaxRetriesChange = (event) => saveMaxRetries(status, event.target.value, max_retries, building);
  // const handleTimeoutChange = (event) => saveTimeout(event.target.value);

  return (
    <Dialog open={open} maxWidth="xxl" BackdropProps={{ invisible: true }}
        style={{ backgroundColor: '#01150d4f' }} sx={{ '& .MuiDialog-paper': { backgroundColor: '#04221a', borderRadius: 5 }}} PaperProps={{ style: { borderRadius: 5 }}} >
      {/* <Drawer anchor="right" open={open} onClose={handleClose} ModalProps={{ hideBackdrop: true, disableEnforceFocus: true, disableRestoreFocus: true, }} PaperProps={{ style: { width: 750, borderRadius: 5 } }}
       sx={{ backgroundColor: 'white', ".MuiDrawer-modal": { inset: 'unset !important' }, ".MuiPaper-root": { backgroundColor: '#04221a' } }}> */}
      {/* <Box sx={{
        mt: 1.5, mr: 1, mb: 6, ml: 3,
        display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)',
      }}> */}
        <DialogTitle sx={{ pl: 4, mb: 1, fontWeight: 500 }}>
          <CodeIcon sx={{ color: '#017322', marginRight: '13px', marginBottom: '-5px' }} />
          {actionName ? (
            <>
              Automation &nbsp;&nbsp;&nbsp;
              <input value={actionName} onChange={handleActionNameChange}
                style={{
                  fontFamily: 'Outfit,sans-serif', fontSize: '1.125rem', fontWeight: 500, color: '#01a22c',
                  width: 300, border: 'none', outline: 'none', backgroundColor: 'transparent'
                }} />
            </>
          ) : 'Automation'}

          <IconButton aria-label="close" onClick={handleClose} size="small" sx={{ position: 'absolute', right: 10, top: 10, color: (theme) => theme.palette.grey[600] }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ pb: 5, pl: 1 }}>
          {status === 'testing' ? <p style={{ paddingLeft: '25px', paddingTop: '20px', lineHeight: '27px' }}>This automation is currently being tested...<br />Please continue the conversation in the workflow builder.</p> : null}
          {status === 'building' ? <p style={{ paddingLeft: '25px', paddingTop: '20px', lineHeight: '27px' }}>This automation is currently being built...<br />Please continue the conversation in the workflow builder.</p> : null}
        </Box>
        {/* <Box ref={boxRef} className="scrollable" sx={{ flexGrow: 1, pr: 3, overflowX: 'hidden', overflowY: 'scroll' }} >
          <Chat conversation={conversation} sendMessage={sendMessage} tweakAutomationTask={tweakAutomationTask} debugAutomationTask={debugAutomationTask} 
          startAutomationTask={startAutomationTask} completeAutomationTask={completeAutomationTask} inspectFiles={inspectFiles} status={status} building={building}
          editConverstation={editConversation} resumeAutomationTask={resumeAutomationTask} runOnly={runOnly} />
        </Box> */}
      {/* </Box> */}

      <Box sx={{
        pr: 6, pb: 1.5, pl: 3, mb: 1.5,
        position: 'absolute', left: 0, bottom: 0, width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2.5,
        fontSize: 14, fontWeight: 400, color: '#e8ffef',
        '& .MuiSelect-select': { minWidth: '8px !important', paddingLeft: '4px' },
        '& .MuiInput-root::before': { borderBottomColor: '#0a593f' }
      }}>
        <Box sx={{ color: '#04231a', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          {actionId}
        </Box>

        {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <ReplayIcon sx={{ width: 15, mr: 1.1, color: '#00a52d' }} />
          <span>max code retries</span>
          <FormControl sx={{ ml: 0.5, mt: 0.4, color: 'white' }} size="small" variant="standard">
            <Select value={max_retries} onChange={handleMaxRetriesChange} sx={{ fontSize: 14 }} >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={16}>16</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
          <ScheduleIcon sx={{ width: 15, mr: 1.1, color: '#00a52d' }} />
          <span>max run time</span>
          <FormControl sx={{ ml: 0.5, mt: 0.4, color: 'white' }} size="small" variant="standard">
            <Select value={timeout} onChange={handleTimeoutChange} sx={{ fontSize: 14 }} >
              <MenuItem value={1}>1 s</MenuItem>
              <MenuItem value={5}>5 s</MenuItem>
              <MenuItem value={10}>10 s</MenuItem>
              <MenuItem value={30}>30 s</MenuItem>
              <MenuItem value={120}>2 m</MenuItem>
              <MenuItem value={300}>5 m</MenuItem>
              <MenuItem value={600}>10 m</MenuItem>
              <MenuItem value={1800}>30 m</MenuItem>
              <MenuItem value={3600}>1 h</MenuItem>
            </Select>
          </FormControl>
        </Box> */}

      </Box>
    {/* </Drawer> */}
    </Dialog>
  );
};

export default AutoCodeEditor;