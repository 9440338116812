import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

import useSettings from '../../hooks/useSettings';
import { _analyticPost, _analyticOrderTimeline, _analyticTraffic } from '../../_mock';
import Page from '../../components/Page';
import Flow from '../../automations/Flow'


export default function PresetsKeywordClustering() {
  const theme = useTheme();

  const { themeStretch } = useSettings();

  return (
    <Page title="Keyword Clustering Preset">
      <Container maxWidth='xl'>
        <div style={{ 
            height: "calc(100vh)",
            width: "calc(100vw)",
            position: "absolute",
            top: "0",
            left: "0",
          }}>
          <Flow />
        </div>
      </Container>
    </Page>
  );
}
