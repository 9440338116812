import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InvoiceNewEditStatusDate from 'src/sections/@dashboard/invoice/new-edit-form/InvoiceNewEditStatusDate';
import { Handle, Position, getIncomers, getOutgoers } from "react-flow-renderer"

import axiosInstance from "src/utils/axios.js";
import { useSelector, useDispatch } from "react-redux";
import { store } from '../store';

import forge from 'node-forge';

import publicKeyPem from '../../public_rsa.pem';

export const fetchCredits = createAsyncThunk(
  'user/getCredits',
  async (userId) => {
    const url = process.env.REACT_APP_BILLING_URL + "/users/" + userId + "/credits";
    try {
      const response = await axiosInstance.get(url);
      console.log(response.data)
      return response.data.credits;
    } catch (error) { 
      console.error(error); 
    }
  }
);

export const updateUserKey = (user, keyName, key, maskedKey) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    // Fetch the contents of the publickKeyPem file
    axiosInstance.get(publicKeyPem)
      .then((response) => {
        const publicKeyPemString = response.data;
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPemString);
        const encryptedKey = publicKey.encrypt(key, 'RSA-OAEP', {
          md: forge.md.sha256.create(),
          mgf1: forge.mgf1.create()
        })
        const encryptedKeyBase64 = forge.util.encode64(encryptedKey);

        let updateObj = {
          keys: {
            [keyName]: { enc_key: encryptedKeyBase64, msk_key: maskedKey }
          }
        };

        firestore.collection('users').doc(user.id).set(updateObj, { merge: true })
          .then(() => console.log('key updated'))
          .catch((err) => console.log(err));
      })
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: true,
  },
  reducers: {
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    decreaseCredits: (state, action) => {
      state.credits -= action.payload.amount;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCredits.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.credits = action.payload;
        state.loading = false;
        state.error = false
      })
      .addCase(fetchCredits.rejected, (state, action) => {
        state.error = true
        state.loading = false;
      })
  }
})


export const { decreaseCredits } = userSlice.actions

export const selectCredits = state => state.user.credits;
export const selectCreditsLoading = state => state.user.loading;

export default userSlice.reducer
