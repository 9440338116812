import Box from '@mui/material/Box';


const DefaultEditor = ({fileExtension}) => {

  return (
    <Box sx={{ color: 'rgb(208, 255, 221, 0.8)', textAlign: 'center', backgroundColor: '#011c15', pt: 10, pr: 5, pb: 10, pl: 5, display: 'flex', justifyContent: 'center', borderRadius: 0.5 }}>
      .{ fileExtension } Files are not editable here.<br />You can still use this file in your algorithm.
    </Box>
  );
}

export default DefaultEditor;