import { useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';

import useAuth from '../../../hooks/useAuth';


const AlgorithmsItem = ({ template, algorithm, active, loadAlgorithm, deleteAlgorithm, updateAlgorithmName, duplicateAlgorithm }) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(algorithm.name);
  const [hover, setHover] = useState(false);
  const { user } = useAuth();

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    setEditMode(false);
    updateAlgorithmName(algorithm.id, name);
  };

  const handleChange = (event) => {
    // Limit name length to 20 characters
    if (event.target.value.length <= 23) setName(event.target.value);
  };

  const handleLoadAlgorithm = () => {
    if (!editMode) loadAlgorithm(algorithm.id); 
  };

  const handleDeleteAlgorithm = () => {
    deleteAlgorithm(algorithm.id);
  };

  return (
    <ListItem sx={{ pt:1, pb:1, ':hover': { bgcolor: '#051b13', }}} key={algorithm.id}
    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
      <ListItemIcon sx={{color: '#008e27'}}>
        {active ? <PlayArrowIcon style={{width: '20px'}} /> : <KeyboardOptionKeyIcon style={{width: '20px'}} />}
      </ListItemIcon>
      {editMode ? (
        <input
          value={name}
          style={{
            fontSize: 'inherit', fontSize: '0.875rem',
            padding: 0, margin: 0, border: 'none', width: '100%', outline: 'none',
            backgroundColor: 'transparent',
            color: 'rgb(208, 255, 221)',
          }}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyPress={(event) => event.key === 'Enter' ? handleSave() : null }
          autoFocus
        />
      ) : (
        <ListItemText button onClick={handleLoadAlgorithm} primaryTypographyProps={{ fontWeight: active ? 600 : 400 }}
          primary={algorithm.name} style={{ cursor: 'pointer', color: active ? '#ffffff' : 'rgb(208, 255, 221)' }} />
      )}
      {hover ? (
        <>
          <ListItemSecondaryAction onClick={handleEdit}>
            <IconButton size="small" edge="end" sx={{ mr: 5 }}>
              {editMode ? null : <EditIcon style={{ fontSize: '13px' }} />}
            </IconButton>
          </ListItemSecondaryAction>
          <ListItemSecondaryAction button onClick={() => duplicateAlgorithm(algorithm.id, user.id)}>
            <IconButton size="small" edge="end" sx={{ mr: 2.4 }}>
              <ContentCopySharpIcon style={{ fontSize: '12px' }} />
            </IconButton>
          </ListItemSecondaryAction>
          <ListItemSecondaryAction button onClick={handleDeleteAlgorithm}>
            <IconButton size="small" edge="end">
              <ClearOutlinedIcon style={{ fontSize: '14px' }} />
            </IconButton>
          </ListItemSecondaryAction>
        </>
      ): null }
    </ListItem>
  )
}

export default AlgorithmsItem;