import React from "react"
import { getBezierPath, getEdgeCenter } from "react-flow-renderer"
// import { getBezierPath, getEdgeCenter } from "reactflow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux";

import { keyframes } from "styled-components";


const foreignObjectSize = 40

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  label,
  source,
  markerEnd,
}) {
  const dashdraw = keyframes`
  0% {
    stroke-dashoffset: 49;
  }
  `

  const status = useSelector((state) => state.keywordclustering.nodes.find(x => x.id === source).data.status);

  const labelSpace2 = "M" + (targetX - 20) + " " + targetY + " l" + 20 + " " + 0;

  const something = {
    // sourceX: sourceX + 10,
    sourceX,
    sourceY,
    sourcePosition,
    targetX: targetX - 20,
    // targetX,
    targetY,
    targetPosition,
  };

  const edgePath = getBezierPath(something) + labelSpace2
  // const edgePath = getBezierPath(something)


  // const [edgeCenterX, edgeCenterY] = getEdgeCenter({
  //   sourceX,
  //   sourceY,
  //   targetX,
  //   targetY,
  // })

  return (
    <>
      <g>
        <path
          id={id}
          // className="custom-edge"
          style={{
            ...style, 
            // stroke: status ? "#03531c" : "#03531c80",
            stroke: status ? "#028e28b3" : "#03531c80",
            strokeOpacity: status ? "100%" : "50%",
            transition: "stroke 0.7s ease 0.1s",
            strokeDasharray: status ? "49 0.5" : "none",
            strokeDasharrayOffset: 49,
            animation: status ? dashdraw + " 2.5s linear infinite": "none",
            strokeWidth: 43,
          }}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
        />
      </g>
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: '17px' }}
          startOffset="94%"
          textAnchor="end"
          fill="#D0FFDD"
          fontFamily="Outfit"
          letterSpacing="0.3px"
          fontWeight="500"
          alignmentBaseline="middle"
        >
          {label}
        </textPath>
        {/* <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <body>
            <button
              className="edgebutton"
              // onClick={(event) => onEdgeClick(event, id)}
            >
              <FontAwesomeIcon icon={faPlus} size="xs" />
            </button>
          </body>
        </foreignObject> */}
      </text>
    </>
  )
}
