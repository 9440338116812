import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      {/* <ProgressBar /> */}

      {!isDashboard && (
          <Box sx={{
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor: theme.palette.background.default,
          }}>
            <Logo disabledLink sx={{ width: 256, height: 256, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            <CircularProgress sx={{ color: '#00dd3c' }} size="1.5rem" />
          </Box>
      )}
    </>
  );
}
