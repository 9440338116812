export const generateID = () => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const maxMultiple = Math.floor(256 / chars.length) * chars.length;
  if (!(0 < maxMultiple && maxMultiple < 256)) {
    throw new Error(`Expect maxMultiple to be (0, 256), but got ${maxMultiple}`);
  }

  let autoId = '';
  const targetLength = 20;
  while (autoId.length < targetLength) {
    const bytes = new Uint8Array(40);
    window.crypto.getRandomValues(bytes);
    for (let i = 0; i < bytes.length; ++i) {
      if (autoId.length < targetLength && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length);
      }
    }
  }
  if (autoId.length !== targetLength) {
    throw new Error('Invalid auto ID: ' + autoId);
  }

  return autoId;
}