import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';

import useAuth from '../../hooks/useAuth';

import { createFirstoreAlgorithm, deleteFirstoreAlgorithm, updateFirstoreAlgorithmName, duplicateFirstoreAlgorithm } from '../../redux/slices/algorithm';


const TemplateContext = ({ algorithmId }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const duplicateAlgorithm = () => {
    dispatch(duplicateFirstoreAlgorithm(algorithmId, user.id));
  };

  return (
    <IconButton onClick={duplicateAlgorithm}variant="contained" sx={{
      pl: 2, pr: 2, mr: 10, pt: 1.2, pb: 1.2,
      backgroundColor: '#008e27',
      borderRadius: '4px',
      boxShadow: 'none',
      fontSize: '12px',
      letterSpacing: '0.2px',
      fontWeight: 500,
      fontFamily: 'Outfit, sans-serif',
    }}>
      <AddIcon sx={{ fontSize: '15px' }} /> &nbsp;&nbsp;Use template
    </IconButton>
  )
}

export 
default TemplateContext;