import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import ListAlgorithms from './ListAlgorithms';


NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();

  return (
    <Box {...other} sx={{ pt: 1, pr: 2, pb: 4, pl: 2.5, 
              // bgcolor: 'red',
              bgcolor: '#01150dcf',
              backdropFilter: 'blur(20px)',
              borderRadius: '0 0 10px 0',
     }}>

        <ListAlgorithms />

      {/* {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))} */}
    </Box>
  );
}

export default NavSectionVertical;