import { compose } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddIcon from '@mui/icons-material/Add';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';

import useAuth from '../../../hooks/useAuth';
import AlgorithmsItem from './AlgorithmsItem';
import AlgorithmsTemplate from './AlgorithmsTemplate';
import withRouter from '../../../hocs/withRouter';
import { createFirstoreAlgorithm } from '../../../redux/slices/algorithm';
import { deleteFirstoreAlgorithm } from '../../../redux/slices/algorithm';
import { updateFirstoreAlgorithmName } from '../../../redux/slices/algorithm';
import { duplicateFirstoreAlgorithm } from '../../../redux/slices/algorithm';


const withUser = Component => props => {
  const { user } = useAuth();
  return <Component {...props} {...{ user }} />;
};

const ListAlgorithms = ({ algorithms, createAlgorithm, deleteAlgorithm, updateAlgorithmName, duplicateAlgorithm, params }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const algorithmId = params.id;

  const templates = [
    {
      id: 'UQigiJ8WtKsXWBbqJGoV',
      name: 'SERP keyword clustering '
    },
    {
      id: '6sKVYufBRIR9Ex46ahup',
      name: 'GPT 3.5 turbo'
    },
    {
      id: '6jBh6dEfn8Dg80p9FYoE',
      name: 'URL scraping'
    },
    {
      id: 'fEuUzbfA1lGNaedzgfwi',
      name: 'Domain pagerank'
    },
  ]

  const loadAlgorithm = (id) =>  navigate('/algorithm/' + id);

  const handleDeleteAlgorithm = (id) => {
    if (id === algorithmId) navigate('/algorithm');
    deleteAlgorithm(id);
  }

  // Sort algorithms alphabetically by name and then by id and keep algorithms with the name 'Algorithm' at the top
  if (algorithms) {
    var orderedAlgorithms = [...algorithms]
    orderedAlgorithms.sort((a, b) => {
      if (a.name === 'Algorithm' && b.name !== 'Algorithm') return -1;
      if (a.name !== 'Algorithm' && b.name === 'Algorithm') return 1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });
  }

  return (
    <>
      <List dense={true} sx={{ mt: 5 }}>
        {/* <ListSubheader sx={{mb:0.5, ml: 0.5, fontSize: '0.9rem', color:'#fff', fontWeight:600, backgroundColor: '#ffffff00'}}>Algorithms</ListSubheader> */}
        {algorithms && orderedAlgorithms.map((algorithm) => (
          <AlgorithmsItem template={false} algorithm={algorithm} active={algorithm.id === algorithmId} 
          loadAlgorithm={loadAlgorithm} deleteAlgorithm={handleDeleteAlgorithm} 
          updateAlgorithmName={updateAlgorithmName} duplicateAlgorithm={duplicateAlgorithm} />
        ))}
        <ListItem button onClick={() => createAlgorithm(user.id)} sx={{pt:1, pb:1, mt:1.5, ':hover': {bgcolor:'#051b13'}}}>
          <ListItemIcon style={{color:'#008e27'}}>
            <AddIcon style={{width: '20px'}} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{fontWeight:600}} primary="Add Algorithm" sx={{color:'#008e27'}} />
        </ListItem>
      </List>

      {/* <List dense={true}>
        <ListSubheader sx={{mb:0.5, mt: 1, ml: 0.5, fontSize: '0.9rem', color:'#fff', fontWeight:600, backgroundColor: '#ffffff00'}}>Templates</ListSubheader>
        {templates.map((template) => (
          <AlgorithmsTemplate template={template} active={template.id === algorithmId} 
          loadAlgorithm={loadAlgorithm} duplicateAlgorithm={duplicateAlgorithm} />
        ))}
      </List> */}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  createAlgorithm: (userId) => dispatch(createFirstoreAlgorithm(userId)),
  deleteAlgorithm: (id) => dispatch(deleteFirstoreAlgorithm(id)),
  updateAlgorithmName: (id, name) => dispatch(updateFirstoreAlgorithmName(id, name)),
  duplicateAlgorithm: (id, userId) => dispatch(duplicateFirstoreAlgorithm(id, userId)),
});

const enhance = compose(
  withRouter,
  withUser,
  firestoreConnect((props) => [
    {
      collection: 'algorithms',
      storeAs: 'allAlgorithms',
      where: ['user_id', '==', props.user.id],
      select: ['name', 'id'],
    }
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    algorithms: ordered.allAlgorithms,
  }), mapDispatchToProps),
);

export default enhance(ListAlgorithms);