import { useCallback, useState, memo, useMemo } from "react"
import { Handle, Position } from "react-flow-renderer"
// import { Handle, Position } from "reactflow"

import { useSelector, useDispatch } from "react-redux";
import { setStatus } from "../redux/slices/keywordclustering.js";


function TransformNode({ id, data }) {
  const dispatch = useDispatch();
  
  const status = useSelector((state) => state.keywordclustering.nodes.find(x=> x.id === id).data.status);

  // Check if status of all incoming nodes is true
  // const status = useSelector((state) => {
  //   const node = state.keywordclustering.nodes.find(x=> x.id === id);
  //   const incomers = getIncomers(node, state.keywordclustering.nodes, state.keywordclustering.edges);
  //   const status = incomers.every(x => x.data.status);
  //   // dispatch(setStatus({id: id, status: status}));
  //   return status;
  // });

  const inputHandles = useMemo(
    () =>
      Array.from({ length: data.inputHandleCount }, (x, i) => {
        const handleId = `input${i + 1}`;
        return (
          <Handle
            // className="transform-input-handle"
            type="target"
            position={Position.Left}
            key={handleId}
            id={handleId}
          />
        );
      }),
    [data.inputHandleCount]
  );

  return (
    <div className="transform-node">
      {" "}
      <h3>{data.label}</h3>
      <div className="transform-wrapper">
        <div className="transform-input">
          {inputHandles}
        </div>
        <div className="transform-visual"></div>
        <div className="transform-output">
          <Handle
            // className="transform-output-handle"
            type="source"
            position={Position.Right}
            id="output1"
            style={{
              opacity: status ? "100%" : "55%",
              transition: "opacity 0.7s ease 0.1s",
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(TransformNode)
