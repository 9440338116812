import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const navigationRef = { current: null };

export const useNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigationRef.current = navigate;
  }, [navigate]);

  return navigate;
};

export const navigate = (...args) => {
  if (navigationRef.current) {
    navigationRef.current(...args);
  } else {
    console.error('Navigate function is not initialized yet.');
  }
};