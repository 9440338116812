
import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'

let useSnackbarRef;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export default {
  toast(msg, variant) {
    useSnackbarRef.enqueueSnackbar(msg, { variant })
  }
}