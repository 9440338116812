import axios from 'axios';

import { generateID } from '../../algorithm/utils/misc'

import { navigate } from '../../hooks/useNavigation';

import SnackbarUtils from '../../utils/SnackbarUtils';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { actions } from './kanban';


const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_GATEWAY_URL : 'http://localhost:8080';
// const apiUrl = process.env.REACT_APP_API_GATEWAY_URL;

const parseListToObject = (list) => {
  return list.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

export const deepCopy = (obj) => {
  if (obj === null || typeof obj !== 'object') return obj;
  let copy;
  if (Array.isArray(obj)) {
    copy = [];
    for (let i = 0; i < obj.length; i++) copy[i] = deepCopy(obj[i]);
  } else {
    copy = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
}

const saveAlgorithm = (firestore, algorithmId, algorithm, run=true ) => {
  console.log(algorithm);
  firestore.collection('algorithms').doc(algorithmId).set(algorithm)
  .then(() => {
    console.log("Updated algorithm", algorithmId, "in firestore")
    if (run) runAlgorithm(algorithmId);
  })
  .catch((err) => {
    console.log(err);

    // If error message contains 'Missing or insufficient permissions'
    if (err.message.includes('Missing or insufficient permissions')) {
      // Show toast message
      SnackbarUtils.toast("Duplicate algorithm first.", 'error');
    } else {
      // Show toast message
      SnackbarUtils.toast('Could not run algorithm', 'error');
    }
  })
}

const runAlgorithm = (algorithmId) => {
  console.log("Run algorithm");
  const url = `${apiUrl}/algorithms/${algorithmId}/run`;

  // Add Authorization header
  const token = localStorage.getItem('accessToken');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  
  axios.get(url)
    .then((response) => {})
    .catch((error) => {
      // If status is 404 and the message is 'User has not set an openai api key' 
      // redirect to settings page and show toast message
      if (error.response.status === 404 && error.response.data.message === 'User has not set an openai api key') {
        navigate('/user/account')
        SnackbarUtils.toast('Please set your OpenAI API key', 'error');
      }

      if(error.response.status === 403 && error.response.data.message === 'User is not allowed to run this algorithm') {
        SnackbarUtils.toast('You are not allowed to run this algorithm, duplicate it first', 'error');
        // return
      }
      // If status is 403 and the message is 'User subscription is not active'
      // redirect to settings page and show toast message
      else if (error.response.status === 403) {
        navigate('/user/account')
        SnackbarUtils.toast('Please activate your subscription', 'error');
      }
    });
}

export const completeAlgorithm = (algorithmId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection('algorithms').doc(algorithmId).update({
      [`conversation.messages`]: [],
      [`conversation.status`]: 'summarize',
    })
    .then(() => {
      console.log("Updated algorithm conversation", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const editConverstation = (algorithmId, actionId, index) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firstore = getFirestore();
    // Get current conversation from action in algorithm
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    const conversation = algorithm.actions[actionId].data.conversation;

    const newConversation = conversation.slice(0, index);

    // Check if the last system message that starts with 'Current' was dropped
    let lastCurrentMessage = null;
    for (let i = index; i < conversation.length; i++) {
      const message = conversation[i];
      if (message.role === 'system' && message.content.startsWith('Connected')) {
        lastCurrentMessage = message;
      }
    }

    // If it was dropped, add it back
    if (lastCurrentMessage !== null) {
      newConversation.push(lastCurrentMessage);
    }

    // Update the conversation in firestore and increment the action version
    firstore.collection('algorithms').doc(algorithmId).update({
      [`actions.${actionId}.data.conversation`]: newConversation,
      [`actions.${actionId}.data.status`]: 'llm_message',
      [`actions.${actionId}.data.version`]: firebase.firestore.FieldValue.increment(1)
     })
    .then(() => {
      console.log("Updated algorithm action conversation", algorithmId, "in firestore")
    })
    .catch((err) => console.log(err))
  }
}

export const createFirstoreAlgorithm = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const algorithm = {
      name: 'Algorithm',
      user_id: userId,
      actions: {},
      connectors: {},
      conversation: {
        messages: [{
          role: 'system',
          content: 'Current workflow is empty.',
          ts: Date.now()

        }],
        status: 'pending'
      }
    };
    firestore.collection('algorithms').add(algorithm)
    .then((response) => {
      console.log("Created algorithm in firestore");
    })
    .catch((err) => console.log(err))
  }
}

export const deleteFirstoreAlgorithm = (algorithmId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).delete()
    .then(() => {
      console.log("Deleted algorithm", algorithmId, "in firestore")
      const url = `${apiUrl}/data/${algorithmId}`;
      axios.delete(url)
        .then((response) => {
          console.log("Deleted algorithm", algorithmId, "in google cloud storage");
        })
        .catch((error) => console.log(error));
    })
    .catch((err) => console.log(err))
  }
}

export const updateFirstoreAlgorithmName = (algorithmId, name) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ ['name']: name })
    .then(() => {
      console.log("Updated algorithm name", algorithmId, "in firestore")
    })
    .catch((err) => console.log(err))
  }
}

export const updateFirestoreAlgorithmActionLabel = (algorithmId, actionId, label) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ [`actions.${actionId}.data.label`]: label })
    .then(() => {
      console.log("Updated algorithm action label", algorithmId, "in firestore")
    })
    .catch((err) => console.log(err))
  }
}

export const updateAlgorithmActionSize = (algorithmId, actionId, size) => {
  // console.log("size", size);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ [`actions.${actionId}.data.size`]: size })
    .then(() => {
      console.log("Updated algorithm action size", algorithmId, "in firestore")
    })
    .catch((err) => console.log(err))
  }
}

export const addMessageToAlgorithm = (algorithmId, message) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({
      [`conversation.messages`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: message,
        ts: Date.now()
      }),
      [`conversation.status`]: 'user_message',
    })
    .then(() => {
      console.log("Updated algorithm conversation", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const addMessageToAction = (algorithmId, actionId, message) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ 
      [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: message,
        ts: Date.now()
      }),
      [`actions.${actionId}.data.status`]: 'user_message',
     })
    .then(() => {
      console.log("Updated algorithm action converstation", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const tweakAutoAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ 
      [`actions.${actionId}.data.status`]: 'user_message',
      [`actions.${actionId}.data.building`]: true,
      [`actions.${actionId}.data.built`]: false,
      [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: 'The automation needs to be tweaked'
      }),
      [`actions.${actionId}.data.inputs.${generateID()}`]: { name: '', extension: '' },
      [`actions.${actionId}.data.outputs.${generateID()}`]: { name: '', extension: '' },
     })
    .then(() => {
      console.log("Updated algorithm action conversation", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const debugAutoAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ 
      [`actions.${actionId}.data.status`]: 'user_message',
      [`actions.${actionId}.data.building`]: true,
      [`actions.${actionId}.data.built`]: false,
      [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: 'The automation task needs to be debugged'
      }),
     })
    .then(() => {
      console.log("Updated algorithm action conversation", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const completeAutoAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({
      [`actions.${actionId}.data.status`]: 'user_message',
      [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: 'Mark the automation task as complete'
      }),
    })
      .then(() => {
        console.log("Updated algorithm action conversation", algorithmId, "in firestore")
        runAlgorithm(algorithmId);
      })
      .catch((err) => console.log(err))
  }
}

export const inspectAutoActionFiles = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({
      [`actions.${actionId}.data.status`]: 'user_message',
      [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
        role: 'user',
        content: 'Inspect each file separately'
      }),
    })
      .then(() => {
        console.log("Updated algorithm action conversation", algorithmId, "in firestore")
        runAlgorithm(algorithmId);
      })
      .catch((err) => console.log(err))
  }
}

export const startAutoAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    const actionsCopy = deepCopy(algorithm.actions);
    actionsCopy[actionId].data.status = 'system_message';
    actionsCopy[actionId].data.building = true;

    // Get incomming action ids
    const incommingActionIds = Object.values(algorithm.connectors).filter(connector => connector.target === actionId).map(connector => connector.source);
    // Set them all to started
    incommingActionIds.forEach(incommingActionId => {
      actionsCopy[incommingActionId].data.started = true;
      actionsCopy[incommingActionId].data.completed = 'running';
    });
    saveAlgorithm(getFirestore(), algorithmId, { ...algorithm, actions: actionsCopy });
  }
}

export const resumeAutoAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({
      [`actions.${actionId}.data.status`]: 'system_message',
      [`actions.${actionId}.data.version`]: firebase.firestore.FieldValue.increment(1)
    })
      .then(() => {
        console.log("Updated algorithm action conversation", algorithmId, "in firestore")
        runAlgorithm(algorithmId);
      })
      .catch((err) => console.log(err))
  }
}

export const updateAlgorithmActionMeta = (algorithmId, actionId, automationTask, label) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({ 
      [`actions.${actionId}.data.automation_task`]: automationTask,
      [`actions.${actionId}.data.label`]: label 
    })
    .then(() => console.log("Updated algorithm action automation task", algorithmId, "in firestore"))
    .catch((err) => console.log(err))
  }
}

export const saveActionMaxRetries = (algorithmId, actionId, currentStatus, maxRetries, currentRetries, building) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const updates = {};
    updates[`actions.${actionId}.data.max_retries`] = maxRetries;
    if (currentStatus === 'max_retries_reached' && building && maxRetries > currentRetries) {
      updates[`actions.${actionId}.data.status`] = 'executed_code';
    }
    firestore.collection('algorithms').doc(algorithmId).update(updates)
    .then(() => {
      console.log("Updated algorithm action max retries", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    }) 
    .catch((err) => console.log(err))
  }
}

export const saveConversationTimeout = (algorithmId, timeout) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).update({
      [`conversation.timeout`]: timeout,
    })
    .then(() => {
      console.log("Updated algorithm conversation timeout", algorithmId, "in firestore")
      runAlgorithm(algorithmId);
    })
    .catch((err) => console.log(err))
  }
}

export const saveActionTimeout = (algorithmId, actionId, timeout, building, status) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    console.log("building", building);
    console.log("status", status);
    if (building && status === 'llm_message') {
      console.log("Updating timeout and status");
      firestore.collection('algorithms').doc(algorithmId).update({
        [`actions.${actionId}.data.timeout`]: timeout,
        [`actions.${actionId}.data.status`]: 'system_message',
        [`actions.${actionId}.data.conversation`]: firestore.FieldValue.arrayUnion({
          role: 'system',
          content: 'Timeout updated to ' + timeout + ' s',
          ts: Date.now()
        }),
      })
      .then(() => {
        console.log("Updated algorithm action timeout", algorithmId, "in firestore");
        runAlgorithm(algorithmId);
      })
      .catch((err) => console.log(err))
    } else {
      console.log("Updating timeout")
      firestore.collection('algorithms').doc(algorithmId).update({
        [`actions.${actionId}.data.timeout`]: timeout,
      })
      .then(() => console.log("Updated algorithm action timeout", algorithmId, "in firestore"))
      .catch((err) => console.log(err))
    }
  }
}

export const duplicateFirstoreAlgorithm = (algorithmId, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('algorithms').doc(algorithmId).get()
    .then((doc) => {
      const algorithm = doc.data();
      algorithm.name = algorithm.name + ' (copy)';
      algorithm.user_id = userId;
      const result = firestore.collection('algorithms').add(algorithm)
      .then((response) => {
        const url = `${apiUrl}/data/${algorithmId}/duplicate`;
        const payload = { target_algorithm_id: response.id }
        axios.post(url, payload)
          .then((response) => {
            console.log("Duplicated algorithm", algorithmId, "in firestore"); 
          })
          .catch((error) => console.log(error));
      })
      .catch((err) => console.log(err))
    })
    .catch((err) => console.log(err))
  }
}


export const updateFirestoreAlgorithm = (algorithmId, algorithm, run) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const connectorsObject = parseListToObject(algorithm.connectors);
    const connectorsCopy = deepCopy(connectorsObject);
    const actionsObject = parseListToObject(algorithm.actions);
    const actionsCopy = deepCopy(actionsObject);
    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(
      getFirestore(), 
      algorithmId, {
        ...algorithm,
        actions: activatedActions,
        connectors: connectorsCopy
      },
      run
    );
  }
}

export const toggleAlgorithmAbstraction = (algorithmId, actionId, isOpen) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const connectorsCopy = deepCopy(algorithm.connectors);
    const actionsCopy = deepCopy(algorithm.actions);

    // Toggle abstraction
    const action = actionsCopy[actionId];
    action.data.open = isOpen;

    // Update all actions that have this action as a parent
    for (const key in actionsCopy) {
      if (actionsCopy[key].parentNode === actionId) {
        if (!isOpen) {
          actionsCopy[key].data.openPosition = actionsCopy[key].position;
          actionsCopy[key].position = { x: 0, y: 100 };
        } else {
          actionsCopy[key].position = actionsCopy[key].data.openPosition;
        }
      }
    }

    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions, connectors: connectorsCopy});
  }
}


export const deleteAlgorithmAction = (algorithmId, actionId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const connectorsCopy = deepCopy(algorithm.connectors);
    const actionsCopy = deepCopy(algorithm.actions);

    for (const key in connectorsCopy) {
      if (connectorsCopy[key].source === actionId || connectorsCopy[key].target === actionId) {
        if (actionsCopy[connectorsCopy[key].source].type === 'auto_code') {
          actionsCopy[connectorsCopy[key].source].data.status = 'building';
          // Delete output from the connected action
          delete actionsCopy[connectorsCopy[key].source].data.outputs[connectorsCopy[key].sourceHandle];
        }
        if (actionsCopy[connectorsCopy[key].target].type === 'auto_code') {
          actionsCopy[connectorsCopy[key].target].data.status = 'building';
          // Delete input from the connected action
          delete actionsCopy[connectorsCopy[key].target].data.inputs[connectorsCopy[key].targetHandle];
        }
        if (actionsCopy[connectorsCopy[key].source].type === 'file') {
          delete actionsCopy[connectorsCopy[key].source].data.outputs[connectorsCopy[key].sourceHandle];
        }
        if (actionsCopy[connectorsCopy[key].target].type === 'file') {
          delete actionsCopy[connectorsCopy[key].target].data.inputs[connectorsCopy[key].targetHandle];
        }
        delete connectorsCopy[key];
      }
    }
    for (const key in actionsCopy) {
      if (actionsCopy[key].id === actionId) delete actionsCopy[key];
    }
    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions, connectors: connectorsCopy});

    // Delete action from google cloud storage
    const url = `${apiUrl}/data/${algorithmId}/${actionId}`;
    axios.delete(url)
      .then((response) => {
        console.log("Deleted action", actionId, "from google cloud storage");
      })
      .catch((error) => console.log(error));
  }
}

export const updateAlgorithmActionStatus = (algorithmId, actionId, status) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const actionsCopy = deepCopy(algorithm.actions);
    for (const key in actionsCopy) {
      if (actionsCopy[key].id === actionId) actionsCopy[key].data.status = status;
    }
    const activatedActions = processActions(actionsCopy, algorithm.connectors);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions});
  }
}

export const deleteAlgorithmActionInput = (algorithmId, actionId, inputId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const connectorsCopy = deepCopy(algorithm.connectors);
    for (const key in connectorsCopy) {
      if (connectorsCopy[key].targetHandle === inputId) {
        console.log("Deleting connector", key, "from algorithm", algorithmId)
        delete connectorsCopy[key];
      }
    }
    const actionsCopy = deepCopy(algorithm.actions);
    for (const key in actionsCopy) {
      if (actionsCopy[key].id === actionId) {
        console.log("Deleting input", inputId, "from action", actionId)
        delete actionsCopy[key].data.inputs[inputId];
      }
    }
    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions, connectors: connectorsCopy});
  }
}

export const deleteAlgorithmConnector = (algorithmId, connectorId, connectorSource, connectorTarget) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const connectorsCopy = deepCopy(algorithm.connectors);
    for (const key in connectorsCopy) {
      if (connectorsCopy[key].id === connectorId) {
        delete connectorsCopy[key];
      }
    }
    const actionsCopy = deepCopy(algorithm.actions);
    for (const key in actionsCopy) {
      if (actionsCopy[key].type === 'auto_code' && (actionsCopy[key].id === connectorSource || actionsCopy[key].id === connectorTarget)) {
        actionsCopy[key].data.status = 'io_changed';
        actionsCopy[key].data.version = firebase.firestore.FieldValue.increment(1);
      }
    }
    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions, connectors: connectorsCopy});
  }
}

export const updateAlgorithmAction = (algorithmId, actionId, data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const algorithm = getState().firestore.data.algorithms[algorithmId];
    if (algorithm.user_id === 'template') return;

    const connectorsCopy = deepCopy(algorithm.connectors);
    const actionsCopy = deepCopy(algorithm.actions);
    actionsCopy[actionId].data = deepCopy(data);
    const activatedActions = processActions(actionsCopy, connectorsCopy);
    saveAlgorithm(getFirestore(), algorithmId, {...algorithm, actions: activatedActions, connectors: connectorsCopy});
  }
}

const propagate = (actions, connectors) => {
  // Reset all input and output filenames and extension of auto code actions
  for (const actionId in actions) {
    const action = actions[actionId];
    // if (action.type === 'auto_code' && (action.data.status === 'building' || action.data.building)) {
    //   console.log('resetting filenames');
    //   for (const inputId in action.data.inputs) {
    //     action.data.inputs[inputId].name = '';
    //     action.data.inputs[inputId].extension = '';
    //   }
    //   for (const outputId in action.data.outputs) {
    //     action.data.outputs[outputId].name = '';
    //     action.data.outputs[outputId].extension = '';
    //   }
    // }
  }

  var changes = true;
  while (changes) {
    changes = false;

    // Reset completed status of all actions based on the completed status of incomming actions
    for (const actionId in actions) {
      const action = actions[actionId];

      // if action status is completed
      if (action.data.status === 'completed' || action.data.status === 'running' || action.data.status === 'error') {
        // Get incomming connectors
        const incommingConnectors = Object.values(connectors).filter(connector => connector.target === actionId);
        // Get incomming actions
        const incommingActions = incommingConnectors.map(connector => actions[connector.source]);
        // If any incomming action is not completed or not started, set action complete to false
        if (incommingActions.find(incommingAction => incommingAction.data.status !== 'completed' || (incommingAction.type == 'file' && !incommingAction.data.started))) {
          console.log("resetting completed status of action " + actionId);
          action.data.status = 'pending';
          changes = true;
        }
      }
    }

    // // Propagate filenames and extensions between actions 
    // for (const connectorId in connectors) {
    //   const connector = connectors[connectorId];

    //   // Get sourceActionOutput and targetActionInput
    //   const sourceActionOutput = actions[connector.source].data.outputs[connector.sourceHandle];
    //   const targetActionInput = actions[connector.target].data.inputs[connector.targetHandle];

    //   // If one has a filename and the other doesn't add filename to the other
    //   if (sourceActionOutput.name && !targetActionInput.name && sourceActionOutput.extension && !targetActionInput.extension) {
    //     targetActionInput.name = sourceActionOutput.name;
    //     targetActionInput.extension = sourceActionOutput.extension;
    //     changes = true;
    //   } 
    //   else if (!sourceActionOutput.name && targetActionInput.name && !sourceActionOutput.extension && targetActionInput.extension) {
    //     sourceActionOutput.name = targetActionInput.name;
    //     sourceActionOutput.extension = targetActionInput.extension;
    //     changes = true;
    //   }

    //   // If both have filenames and extensions and they are different, propagate name from file to auto_code
    //   if (sourceActionOutput.name && targetActionInput.name && sourceActionOutput.extension && targetActionInput.extension) {
    //     if ((sourceActionOutput.name !== targetActionInput.name) || (sourceActionOutput.extension !== targetActionInput.extension)) {
    //       if (actions[connector.source].type === 'file' && actions[connector.target].type === 'auto_code') {
    //         targetActionInput.name = sourceActionOutput.name;
    //         targetActionInput.extension = sourceActionOutput.extension;
    //         changes = true;
    //       }
    //       else if (actions[connector.source].type === 'auto_code' && actions[connector.target].type === 'file') {
    //         sourceActionOutput.name = targetActionInput.name;
    //         sourceActionOutput.extension = targetActionInput.extension;
    //         changes = true;
    //       }
    //     }
    //   }
    // }

    // // Propagate filename and extension within File actions
    // for (const actionId in actions) {
    //   const action = actions[actionId];

    //   // If action type is file
    //   if (action.type === "file") {
    //     // Get the input or output that has a filename
    //     const ioWithDataName = Object.values(action.data.inputs).find(io => io.name) || Object.values(action.data.outputs).find(io => io.name);

    //     // Get the input or output that has no name
    //     const ioWithoutDataName = Object.values(action.data.inputs).find(io => !io.name) || Object.values(action.data.outputs).find(io => !io.name);

    //     // If there is an io with name and an io without name
    //     if (ioWithDataName && ioWithoutDataName) {
    //       // Set all inputs and outputs to have the same name
    //       Object.values(action.data.inputs).forEach(input => {
    //         input.name = ioWithDataName.name;
    //         input.extension = ioWithDataName.extension;
    //       });
    //       Object.values(action.data.outputs).forEach(output => {
    //         output.name = ioWithDataName.name;
    //         output.extension = ioWithDataName.extension;
    //       });
    //       changes = true;
    //     }
    //   }
    // }
  }

  // Remove all auto_code inputs and outputs that do not have a filename
  for (const actionId in actions) {
    const action = actions[actionId];
  }
  return actions;
}

const processActions = (actions, connectors) => {
  actions = propagate(actions, connectors);
  return actions;
}