import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers, compose, createStore, applyMiddleware} from 'redux';
import { rootPersistConfig, rootReducer, storeVersion, previousStoreVersion } from './rootReducer';

import { composeWithDevTools } from 'redux-devtools-extension';


import thunk from 'redux-thunk'

import { reduxFirestore, firestoreReducer, getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import { reactReduxFirebase } from 'react-redux-firebase';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

import { resetStore } from "../redux/slices/keywordclustering.js";

// ----------------------------------------------------------------------



const firebaseConfig = {
  // apiKey: "AIzaSyBNj5wleWijE6MEt2RLMIqniXQhLTxQO80",
  // authDomain: "algonext-test-1669034633.firebaseapp.com",
  // projectId: "algonext-test-1669034633",
  // storageBucket: "algonext-test-1669034633.appspot.com",
  // messagingSenderId: "395605387443",
  // appId: "1:395605387443:web:44eaddca74aa855ed168c6",
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}; // from Firebase Console
const rfConfig = {}; // Optional redux-firestore Config Options

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore through Firebase
firebase.firestore();

// const store = configureStore({
//   reducer: persistReducer(rootPersistConfig, rootReducer),
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//       immutableCheck: false,
//     }),
// });

// const persistor = persistStore(store);

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, rfConfig),
  // reactReduxFirebase(firebase),
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))),
)(createStore);

const store = createStoreWithFirebase(
  rootReducer, {},
);


const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

if (previousStoreVersion !== storeVersion) {
  // persistor.purge();
  dispatch(resetStore());
}

// export { store, persistor, dispatch, useSelector, useDispatch };
export { store, dispatch, useSelector, useDispatch, firebase};
