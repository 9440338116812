import { compose } from 'redux';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNodes, useEdges, useUpdateNodeInternals } from 'react-flow-renderer';

import { updateAlgorithmAction, deleteAlgorithmAction  } from '../../redux/slices/algorithm';
import withRouter from '../../hocs/withRouter';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';

import FileActionHandle from '../handles/FileActionHandle';
import PlayButton from "../visuals/play.svg"
import PauseButton from "../visuals/pause.svg"
import FileEditor from "../inputs/FileEditor"

import { keyframes } from "styled-components";



function FileAction({ id, data, params, connecting, selectedHandle, algorithmUserId, runOnly }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const dispatch = useDispatch();
  const connectors = useEdges();
  const actions = useNodes();
  const { enqueueSnackbar } = useSnackbar();

  const [hover, setHover] = useState(false);
  const [openFileEditor, setOpenFileEditor] = useState(false);

  const { status, started } = data;
  const algorithmId = params.id;

  const outputConnector = connectors.find(x => x.source === id) || false;
  const inputConnector = connectors.find(x => x.target === id) || false;
  const isConnected = outputConnector || inputConnector;

  const inputAction = inputConnector ? actions.find(x => x.id === inputConnector.source) || false : false;
  const outputAction = outputConnector ? actions.find(x => x.id === outputConnector.target) || false : false;
  // const inputOutput = inputAction ? inputAction.data.outputs[inputConnector.sourceHandle] : false;
  // const output = data.outputs[Object.keys(data.outputs)[0]];

  // const incommingOutputId = () => {
  //   if (inputAction && inputAction.data.status === 'completed' && inputOutput) return inputConnector.sourceHandle;
  //   else return false;
  // }


  const handleopenFileEditor = () => {
    // if (data.outputs[Object.keys(data.outputs)[0]].coerced_data_types === "any") {
    //   enqueueSnackbar('Select data type first', { variant: 'error' });
    //   return;
    // }
    setOpenFileEditor(true); 
  }

  useEffect(() => updateNodeInternals(id), [connectors]);

  const handleCloseFileEditor = () => setOpenFileEditor(false); 
  const toggleStarted = () => dispatch(updateAlgorithmAction(algorithmId, id, { ...data, started: !started }));
  const deleteAction = () => {
    if (runOnly) enqueueSnackbar('Add your OpenAI API key first', { variant: 'warning' });
    else dispatch(deleteAlgorithmAction(algorithmId, id));
  }

  const buttonStyle = {
    cursor: "pointer",
    height: 52, width: 57, boxShadow: "0px 0px 10.5128px rgba(0, 0, 0, 0.1)",
    backgroundPosition: "center center", backgroundRepeat: "no-repeat",
    transition: "background-color 0.7s ease",
  }

  const playButtonStyle = {
    backgroundImage: `url(${PlayButton})`, backgroundSize: "95%", backgroundColor: "#051811",
  }

  const pauseButtonStyle = {
    backgroundImage: `url(${PauseButton})`, backgroundColor: '#054C1C',
  }

  const buildingAnimation = keyframes`
  0% { background-color: rgba(0, 162, 44, 1) }
  50% { background-color: rgba(0, 162, 44, 0.5) }
  90% { background-color: rgba(0, 162, 44, 1) }
`;

  const buildingIconAnimation = keyframes`
  0% { color: rgba(0, 162, 44, 1) }
  50% { color: rgba(0, 162, 44, 0) }
  80% { color: rgba(0, 162, 44, 1) }
`;

const buildingHeadingAnimation = keyframes`
  0% { color: rgba(255, 255, 255, 1) }
  50% { color: rgba(255, 255, 255, 0) }
  80% { color: rgba(255, 255, 255, 1) }
`;

  const errorAnimation = keyframes`
  0% { border-color: rgba(255, 0, 0, 1) }
  50% { border-color: rgba(255, 0, 0, 0) }
  90% { border-color: rgba(255, 0, 0, 1) }
`;

  const isBuilding = inputAction?.data?.status == 'building' || inputAction?.data?.status == 'testing' || outputAction?.data?.status == 'building' || outputAction?.data?.status == 'testing';

  const renderFullAction = () => {

    const fileSize = () => {
      if (data.size) {
        if (data.size < 1024) return data.size + ' b';
        else if (data.size < 1024 * 1024) return Math.round((data.size / 1024) * 10) / 10 + ' Kb';
        else if (data.size < 1024 * 1024 * 1024) return Math.round((data.size / 1024 / 1024) * 10) / 10 + ' Mb';
        else return (Math.round((data.size / 1024 / 1024 / 1024) * 10) / 10 + ' Gb');
      }
      return '';
    }

    return (
      <div className="manual-action" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ backgroundColor: 'none', }} >
        <h3 onClick={handleopenFileEditor} style={{
          fontFamily: "Outfit", fontSize: "18px", fontWeight: "500", lineHeight: "18px", letterSpacing: "0.5px",
          paddingTop: "24px", paddingBottom: "20px", paddingLeft: "27px", color: "#ffffff",
          overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", paddingRight: '35px', cursor: "pointer",
          animation: isBuilding ? buildingHeadingAnimation + " 3s ease-out infinite" : null,
        }}>
          <FileIcon sx={{ color: 'rgb(0, 162, 44)', marginRight: '15px', marginBottom: '-6px', width: '26px', height: '26px', 
          animation: isBuilding ? buildingIconAnimation + " 3s ease-out infinite" : null,
           }} />
          {data.label ? data.label : 'File'}
          {/* {output.extension ? ( <span style={{fontWeight: 500, color: '#d0ffdd', marginLeft: '2px'}}>.{output.extension}</span>) : null} */}
        </h3>
        <IconButton aria-label="close" onClick={deleteAction} size="large" disableRipple={true}
          sx={{ position: 'absolute', right: 7, top: 7, color: '#438555', display: hover ? 'block' : 'none', }}
        >
          <CloseIcon />
        </IconButton>

        <div className="input" style={{ cursor: 'pointer' }}>
          {data.inputs && Object.keys(data?.inputs).length !== 0 ? (
            <FileActionHandle active={selectedHandle && Object.keys(data.inputs)[0] === selectedHandle.handleId} id={Object.keys(data.inputs)[0]}
              isConnected={inputConnector} actionId={id} connecting={connecting} filename="" type="target" label={''} />
          ) : null}
          <div onClick={handleopenFileEditor} style={{
            height: "52px", cursor: "pointer", fontSize: "15px", color: "white", borderRadius: "0", transform: "none",
            position: "relative", border: "none", clipPath: "polygon(38px 52px, 28px 52px, 0px 48px, 0px 5px, 28px 0px, 38px 0px)",
            opacity: status === 'completed' ? "100%" : "100%", 
            backgroundPosition: "center center", backgroundRepeat: "no-repeat", width: 36, display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: "rgb(0, 162, 44)",
          }}>
            {hover ? <EditIcon fontSize="22" /> : null}
          </div>
          <div onClick={handleopenFileEditor} style={{
            height: 52, width: 207, background: "linear-gradient(-90deg, #00611A 0%, #044B1B 20.59%)", boxShadow: "0px 0px 10.5128px rgba(0, 0, 0, 0.1)",
            opacity: status === 'completed' || status === 'running' ? "100%" : "80%", border: "none", fontFamily: "Outfit", fontStyle: "normal",
            fontWeight: 400, fontSize: 17, letterSpacing: "0.4px", paddingLeft: 30, lineHeight: "52px", display: "inline-block",
            overflow: 'hidden', paddingRight: 20, textOverflow: "ellipsis", color: "rgb(208, 255, 221)",
            animation: status === 'running' ? "pulse 3s cubic-bezier(1, 0.9, 0.9, 1.0) infinite" : "none", cursor: "pointer",
          }}>
            <span style={{ display: 'inline-block', float: 'left' }}>{fileSize()}</span>
          </div>
          <div onClick={toggleStarted} style={started ? { ...buttonStyle, ...pauseButtonStyle } : { ...buttonStyle, ...playButtonStyle }}>
          </div>
          <div style={{
            clipPath: "polygon(35px 48px, 16px 52px, 0px 52px, 0px 0px, 16px 0px, 35px 5px)",
            position: "relative", height: "52px", width: "35px", borderRadius: "0",
            border: "none", background: "#00a22c",
            cursor: "pointer",
            fontSize: "15px", color: "white",
            transform: "none",
            background: "rgb(0, 162, 44)",
            opacity: status === 'completed' ? "100%" : "100%", 
          }}>

          </div>
          <div style={{
            width: 0, backgroundColor: status === 'completed' ? "#054b1b" : "#042e19", boxShadow: "0px 0px 10.5128px 4.62562px rgba(0, 0, 0, 0.1)",
            paddingTop: 3,
          }}>
            {data.outputs && Object.keys(data.outputs).length !== 0 ? (
            <FileActionHandle active={selectedHandle && Object.keys(data.outputs)[0] === selectedHandle.handleId} id={Object.keys(data.outputs)[0]} actionId={id} isConnected={outputConnector}
              connecting={connecting} filename="" type="source" label={''} />
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <FileEditor algorithmId={algorithmId} algorithmUserId={algorithmUserId} actionId={id} actionData={data} open={openFileEditor} isConnected={isConnected} handleClose={handleCloseFileEditor} />
      { renderFullAction() }
    </>
  )
}

const enhance = compose(withRouter);
export default enhance(FileAction);