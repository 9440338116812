import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 170, height: 65, paddingLeft: '10px', paddingTop: '8px', ...sx }}>
      <svg width="128" height="33" viewBox="0 0 165 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.5984 28.5286C39.2768 28.5286 38.0853 28.1982 37.024 27.5374C35.9627 26.8766 35.1317 25.9755 34.531 24.8341C33.9303 23.6927 33.6299 22.4111 33.6299 20.9894C33.6299 19.5476 33.9303 18.256 34.531 17.1146C35.1317 15.9733 35.9627 15.0721 37.024 14.4113C38.0853 13.7505 39.2768 13.4201 40.5984 13.4201C41.6397 13.4201 42.5708 13.6304 43.3918 14.0509C44.2128 14.4714 44.8636 15.0621 45.3442 15.8231C45.8448 16.564 46.1152 17.405 46.1552 18.3462V23.5726C46.1152 24.5337 45.8448 25.3848 45.3442 26.1257C44.8636 26.8666 44.2128 27.4573 43.3918 27.8979C42.5708 28.3184 41.6397 28.5286 40.5984 28.5286ZM41.3193 24.8942C42.4206 24.8942 43.3117 24.5337 43.9926 23.8129C44.6734 23.0719 45.0138 22.1208 45.0138 20.9594C45.0138 20.1984 44.8536 19.5276 44.5332 18.9469C44.2329 18.3462 43.8023 17.8856 43.2417 17.5652C42.701 17.2248 42.0602 17.0546 41.3193 17.0546C40.5984 17.0546 39.9576 17.2248 39.3969 17.5652C38.8563 17.8856 38.4258 18.3462 38.1054 18.9469C37.805 19.5276 37.6548 20.1984 37.6548 20.9594C37.6548 21.7403 37.805 22.4312 38.1054 23.0319C38.4258 23.6126 38.8563 24.0732 39.3969 24.4136C39.9576 24.734 40.5984 24.8942 41.3193 24.8942ZM44.8036 28.2283V24.3235L45.4343 20.7791L44.8036 17.2949V13.7205H48.7084V28.2283H44.8036Z" fill="white" />
        <path d="M51.138 28.2283V6.51166H55.0729V28.2283H51.138Z" fill="white" />
        <path d="M63.5754 34.6561C62.0335 34.6561 60.6718 34.3758 59.4904 33.8151C58.3089 33.2744 57.3678 32.5035 56.6669 31.5023L59.16 29.0092C59.7207 29.67 60.3414 30.1706 61.0223 30.5111C61.7231 30.8715 62.5642 31.0517 63.5454 31.0517C64.7669 31.0517 65.728 30.7413 66.4289 30.1206C67.1498 29.4998 67.5102 28.6388 67.5102 27.5374V23.903L68.171 20.7191L67.5402 17.5352V13.7205H71.445V27.4773C71.445 28.9191 71.1046 30.1706 70.4238 31.2319C69.763 32.3133 68.8418 33.1543 67.6604 33.755C66.4789 34.3558 65.1173 34.6561 63.5754 34.6561ZM63.3952 27.8378C62.0936 27.8378 60.9221 27.5274 59.8809 26.9066C58.8396 26.2659 58.0186 25.3948 57.4178 24.2934C56.8371 23.1921 56.5468 21.9606 56.5468 20.5989C56.5468 19.2373 56.8371 18.0158 57.4178 16.9344C58.0186 15.8531 58.8396 15.0021 59.8809 14.3813C60.9221 13.7405 62.0936 13.4201 63.3952 13.4201C64.4765 13.4201 65.4277 13.6304 66.2487 14.0509C67.0897 14.4714 67.7505 15.0521 68.2311 15.793C68.7117 16.5139 68.972 17.365 69.012 18.3462V22.9118C68.972 23.8729 68.7017 24.734 68.2011 25.4949C67.7205 26.2358 67.0597 26.8165 66.2186 27.2371C65.3976 27.6375 64.4565 27.8378 63.3952 27.8378ZM64.1761 24.2634C64.897 24.2634 65.5178 24.1132 66.0384 23.8129C66.5791 23.4925 66.9996 23.0619 67.3 22.5213C67.6003 21.9606 67.7505 21.3298 67.7505 20.629C67.7505 19.9081 67.6003 19.2773 67.3 18.7366C66.9996 18.196 66.5791 17.7755 66.0384 17.4751C65.5178 17.1547 64.897 16.9945 64.1761 16.9945C63.4552 16.9945 62.8245 17.1547 62.2838 17.4751C61.7431 17.7755 61.3226 18.206 61.0223 18.7667C60.7219 19.3073 60.5717 19.9281 60.5717 20.629C60.5717 21.3098 60.7219 21.9306 61.0223 22.4912C61.3226 23.0319 61.7431 23.4624 62.2838 23.7828C62.8245 24.1032 63.4552 24.2634 64.1761 24.2634Z" fill="white" />
        <path d="M80.8196 28.5286C79.3578 28.5286 78.0361 28.1982 76.8547 27.5374C75.6933 26.8566 74.7621 25.9455 74.0613 24.8041C73.3804 23.6426 73.04 22.3511 73.04 20.9293C73.04 19.5076 73.3804 18.236 74.0613 17.1146C74.7421 15.9733 75.6733 15.0721 76.8547 14.4113C78.0361 13.7305 79.3478 13.3901 80.7895 13.3901C82.2713 13.3901 83.593 13.7305 84.7544 14.4113C85.9358 15.0721 86.867 15.9733 87.5478 17.1146C88.2286 18.236 88.569 19.5076 88.569 20.9293C88.569 22.3511 88.2286 23.6426 87.5478 24.8041C86.867 25.9455 85.9358 26.8566 84.7544 27.5374C83.593 28.1982 82.2813 28.5286 80.8196 28.5286ZM80.7895 24.8641C81.5304 24.8641 82.1812 24.704 82.7419 24.3836C83.3226 24.0431 83.7632 23.5826 84.0635 23.0019C84.3839 22.4011 84.5441 21.7203 84.5441 20.9594C84.5441 20.1984 84.3839 19.5276 84.0635 18.9469C83.7431 18.3662 83.3026 17.9156 82.7419 17.5952C82.1812 17.2548 81.5304 17.0846 80.7895 17.0846C80.0686 17.0846 79.4279 17.2548 78.8672 17.5952C78.3065 17.9156 77.8659 18.3662 77.5455 18.9469C77.2252 19.5276 77.065 20.1984 77.065 20.9594C77.065 21.7203 77.2252 22.4011 77.5455 23.0019C77.8659 23.5826 78.3065 24.0431 78.8672 24.3836C79.4279 24.704 80.0686 24.8641 80.7895 24.8641Z" fill="white" />
        <path d="M99.9631 28.2283V19.9081C99.9631 19.047 99.6928 18.3462 99.1521 17.8055C98.6114 17.2648 97.9106 16.9945 97.0495 16.9945C96.4888 16.9945 95.9882 17.1146 95.5477 17.3549C95.1071 17.5952 94.7567 17.9357 94.4964 18.3762C94.2561 18.8167 94.1359 19.3274 94.1359 19.9081L92.6041 19.1271C92.6041 17.9857 92.8444 16.9945 93.325 16.1535C93.8256 15.2924 94.5064 14.6216 95.3675 14.141C96.2485 13.6604 97.2397 13.4201 98.3411 13.4201C99.4024 13.4201 100.354 13.6905 101.195 14.2311C102.036 14.7518 102.696 15.4426 103.177 16.3037C103.658 17.1447 103.898 18.0558 103.898 19.037V28.2283H99.9631ZM90.2011 28.2283V13.7205H94.1359V28.2283H90.2011Z" fill="white" />
        <path d="M113.405 28.5286C111.883 28.5286 110.532 28.2082 109.35 27.5675C108.169 26.9066 107.228 26.0055 106.527 24.8641C105.846 23.7227 105.506 22.4212 105.506 20.9594C105.506 19.5176 105.836 18.236 106.497 17.1146C107.178 15.9733 108.099 15.0721 109.26 14.4113C110.422 13.7305 111.713 13.3901 113.135 13.3901C114.537 13.3901 115.768 13.7105 116.829 14.3513C117.911 14.972 118.752 15.8331 119.353 16.9344C119.973 18.0158 120.284 19.2473 120.284 20.629C120.284 20.8893 120.264 21.1596 120.224 21.4399C120.204 21.7003 120.154 22.0006 120.073 22.3411L107.878 22.3711V19.4275L118.241 19.3974L116.649 20.629C116.609 19.7679 116.449 19.047 116.169 18.4663C115.908 17.8856 115.518 17.4451 114.997 17.1447C114.497 16.8243 113.876 16.6641 113.135 16.6641C112.354 16.6641 111.673 16.8443 111.092 17.2048C110.512 17.5452 110.061 18.0358 109.741 18.6766C109.44 19.2973 109.29 20.0382 109.29 20.8993C109.29 21.7804 109.45 22.5513 109.771 23.2121C110.111 23.8529 110.592 24.3535 111.213 24.714C111.833 25.0544 112.554 25.2246 113.375 25.2246C114.116 25.2246 114.787 25.1044 115.388 24.8641C115.988 24.6038 116.509 24.2234 116.95 23.7227L119.262 26.0356C118.562 26.8566 117.701 27.4773 116.679 27.8979C115.678 28.3184 114.587 28.5286 113.405 28.5286Z" fill="white" />
        <path d="M130.587 28.2283L126.772 22.3411L125.961 21.7403L120.314 13.7205H124.97L128.484 19.037L129.235 19.6077L135.212 28.2283H130.587ZM120.014 28.2283L125.931 19.9381L128.184 22.6114L124.459 28.2283H120.014ZM129.385 21.7103L127.072 19.067L130.527 13.7205H134.942L129.385 21.7103Z" fill="white" />
        <path d="M139.671 28.2283V7.68309H143.606V28.2283H139.671ZM136.277 17.1747V13.7205H147V17.1747H136.277Z" fill="white" />
        <g filter="url(#filter0_d_348_3)">
          <rect width="7.66667" height="23" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#015A1C" />
        </g>
        <g filter="url(#filter1_d_348_3)">
          <rect width="15.3333" height="15.3333" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#015A1C" />
        </g>
        <g filter="url(#filter2_d_348_3)">
          <rect width="23" height="7.66667" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#015A1C" />
        </g>
        <g filter="url(#filter3_d_348_3)">
          <rect width="7.66667" height="15.3333" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#008325" />
        </g>
        <g filter="url(#filter4_d_348_3)">
          <rect width="15.3333" height="7.66667" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#008325" />
        </g>
        <g filter="url(#filter5_d_348_3)">
          <rect width="7.66667" height="7.66667" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.6921 21)" fill="#00B02E" />
        </g>
        <defs>
          <filter id="filter0_d_348_3" x="0.364112" y="10.9354" width="30.9715" height="30.9715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
          <filter id="filter1_d_348_3" x="0.364112" y="5.51426" width="30.9715" height="30.9715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
          <filter id="filter2_d_348_3" x="0.364112" y="0.0931164" width="30.9715" height="30.9715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
          <filter id="filter3_d_348_3" x="5.78526" y="10.9354" width="25.5503" height="25.5503" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
          <filter id="filter4_d_348_3" x="5.78526" y="5.51426" width="25.5503" height="25.5503" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
          <filter id="filter5_d_348_3" x="11.2064" y="10.9354" width="20.1292" height="20.1292" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1.41883" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_348_3" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.61231" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_348_3" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_348_3" result="shape" />
          </filter>
        </defs>
      </svg>

    </Box>
  );

  // const logo = (
  //   <Box sx={{ width: 50, height: 50, ...sx }}>
  //     <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
  //       <defs>
  //         <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
  //           <stop offset="0%" stopColor={PRIMARY_DARK} />
  //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
  //         </linearGradient>
  //         <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
  //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
  //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
  //         </linearGradient>
  //         <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
  //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
  //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
  //         </linearGradient>
  //       </defs>

  //       <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
  //         <path
  //           fill="url(#BG1)"
  //           d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
  //         />
  //         <path
  //           fill="url(#BG2)"
  //           d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
  //         />
  //         <path
  //           fill="url(#BG3)"
  //           d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
  //         />
  //       </g>
  //     </svg>
  //   </Box>
  // );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/algorithm">{logo}</RouterLink>;
}
