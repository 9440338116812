import { combineReducers, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import { createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import algorithmReducer from './slices/algorithm';

import keywordclusteringReducer from './slices/keywordclustering';
import { initialState } from './slices/keywordclustering';




// ----------------------------------------------------------------------
// This version should be incremented every time the store is changed. Doing so will clear the
// local storage on all clients that have a different version of the store in their local storage.
const storeVersion = 0;

const getLocalStorageStoreVersion = () => {
  const reduxRoot = localStorage.getItem('redux-root');
  if (reduxRoot) {
    const reduxRootObj = JSON.parse(reduxRoot);
    const persistObj = JSON.parse(reduxRootObj._persist);
    return persistObj.version;
} else {
    return null;
  }
};


const previousStoreVersion = getLocalStorageStoreVersion();

// localStorage.clear();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // Bumping the version will clear will purge the store from local storage
  version: 1,
  // stateReconciler: hardSet,
};

const keywordClusteringMigrations = {
  0: (state) => (initialState),
  // 1: (state) => (initialState),
}

const keywordclusteringPersistConfig = {
  key: 'keywordclustering',
  storage,
  keyPrefix: 'redux-',
  version: 1,
  // stateReconciler: hardSet,
  // migrate: createMigrate(keywordClusteringMigrations, { debug: false }),
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  // keywordclustering: persistReducer(keywordclusteringPersistConfig, keywordclusteringReducer),
  keywordclustering: keywordclusteringReducer,
  user: userReducer,
  firestore: firestoreReducer,
});

export { rootPersistConfig, rootReducer, storeVersion, previousStoreVersion };
