// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

import { useNavigation } from './hooks/useNavigation';

import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';

// import { useAuth } from './hooks/useAuth';

// import useAuth from './hooks/useAuth';


// import LoadingScreen from './components/LoadingScreen';
// ----------------------------------------------------------------------

export default function App() {
  useNavigation();


  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
          <NotistackProvider>
            <SnackbarUtilsConfigurator />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
