import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import DialogTitle from '@mui/material/DialogTitle';

import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Editor from './Editor';
import FileInfo from './FileInfo';
import { updateAlgorithmAction } from '../../redux/slices/algorithm';

import useAuth from '../../hooks/useAuth';


const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_GATEWAY_URL : 'http://localhost:8080';
// const apiUrl = process.env.REACT_APP_API_GATEWAY_URL;


const FileEditor = ({ algorithmId, algorithmUserId, actionId, isConnected, actionData, open, handleClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  // const output = actionData.outputs[Object.keys(actionData.outputs)[0]];

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const [fileExtension, setFileExtension] = useState(actionData.extension);
  const [filename, setFilename] = useState(actionData.filename);
  const [fileSize, setFileSize] = useState(actionData.size);

  const [newFileDialogOpen, setNewFileDialogOpen] = useState(false);
  const [newFilename, setNewFilename] = useState(false);
  const [newFileExtension, setNewFileExtension] = useState(false);

  useEffect(() => {
    if (open) {
      loadFile(actionId);
      setFilename(actionData.filename);
      setFileExtension(actionData.extension);
    }
  }, [open]);

  useEffect(() => {
    if (file === null) return;
    if (file.size < 1024) setFileSize(file.size + ' bytes');
    else if (file.size < 1024 * 1024) setFileSize(Math.round((file.size / 1024) * 1000) / 1000 + ' Kb');
    else if (file.size < 1024 * 1024 * 1024) setFileSize(Math.round((file.size / 1024 / 1024) * 1000) / 1000 + ' Mb');
    else setFileSize(Math.round((file.size / 1024 / 1024 / 1024) * 1000) / 1000 + ' Gb');
  }, [file]);


  const handleNewFileDialogOpen = () => {
    setNewFilename(filename);
    setNewFileExtension(fileExtension);
    setNewFileDialogOpen(true);
  }

  const handleNewFileDialogClose = () => setNewFileDialogOpen(false);
  const handleFilenameChange = (event) => setFilename(event.target.value);
  const handleNewFilenameChange = (event) => setNewFilename(event.target.value);
  const handleNewFileExtensionChange = (event) => setNewFileExtension(event.target.value);

  const handleNameFieldClick = () => {
    enqueueSnackbar('Filename cannot be changed if file is connected', { variant: 'error' });
  }


  const fileUploadHandler = (event) => {
    setFile(event.target.files[0]);
    // Add more robust logic for parsing extension
    setFileExtension(event.target.files[0].name.split('.').pop());
    if (filename === null || filename === '') setFilename(event.target.files[0].name.split('.').slice(0, -1).join('.'));
  }

  const handleNewFileDialogSave = () => {
    if (newFilename === '') {
      enqueueSnackbar('Filename cannot be empty', { variant: 'error' });
      return;
    }
    if (newFileExtension === '') {
      enqueueSnackbar('File extension cannot be empty', { variant: 'error' });
      return;
    }
    setFilename(newFilename);
    setFileExtension(newFileExtension);
    const blob = new Blob([''], { type: 'text/plain' });
    const newFile = new File([blob], newFilename + '.' + newFileExtension, { type: 'text/plain', lastModified: new Date() });
    setFile(newFile);
    setNewFileDialogOpen(false);
  }

  const saveFile = () => {
    if (file === null) return;
    console.log(user.id, algorithmUserId);
    if (user.id !== algorithmUserId) {
      // Show toast
      enqueueSnackbar('Saving this file is not allowed. Duplicate the algorithm first.', { variant: 'warning' });
      return;
    }
    const formData = new FormData();
    formData.append('file', file, filename + '.' + fileExtension);
    axios.post(`${apiUrl}/data/${algorithmId}/${actionId}`, formData)
      .then(res => console.log(res))
      .catch(err => console.error(err));
  };

  const saveAndClose = () => {
    if (filename === '') {
      enqueueSnackbar('Filename cannot be empty', { variant: 'error' });
      return;
    }
    saveFile();
    saveAction();
    handleClose();
  };

  const saveAction = () => {
    dispatch(updateAlgorithmAction(algorithmId, actionId, {
      ...actionData,
      status: file !== null || actionData.status === 'completed' ? 'completed' : 'pending',
      started: file !== null ? false : actionData.started,
      size: file !== null ? file.size : 0,
      inputs: {
        ...actionData.inputs, [Object.keys(actionData.inputs)[0]]: {
          ...actionData.inputs[Object.keys(actionData.inputs)[0]],
          name: filename,
          extension: fileExtension
        }
      },
      outputs: {
        ...actionData.outputs, [Object.keys(actionData.outputs)[0]]: {
          ...actionData.outputs[Object.keys(actionData.outputs)[0]],
          name: filename,
          extension: fileExtension
        }
      }
    }));
  }

  const loadFile = (fileId) => {
    setLoading(true);

    // Do not allow loading the file when the user is not the owner of the 
    // algorithm and the filename starts with secret.
    if (filename.startsWith('secret.') && user.id !== algorithmUserId) {
      setLoading(false);
      setFile(null);
      enqueueSnackbar('Loading this file is not allowed. Duplicate the algorithm first.', { variant: 'warning' });
      return;
    }
    axios.get(`${apiUrl}/data/${algorithmId}/${fileId}`, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        const newFile = new File([blob], filename, { type: blob.type });
        setFile(newFile);
        setLoading(false);
      })
      .catch((error) => {
        // If 404, file does not exist
        if (error.response.status === 404) {
          setFile(null);
          setLoading(false);
          return;
        }
        console.log(error)
      });
  }

  const downloadFile = () => {
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");

    a.href = url;
    a.download = filename + '.' + fileExtension;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <Dialog open={open} maxWidth="xxl" BackdropProps={{ invisible: true }}
        style={{ backgroundColor: '#01150d4f' }} sx={{ '& .MuiDialog-paper': { backgroundColor: '#04221a', borderRadius: 5 }}} PaperProps={{ style: { borderRadius: 5 }}} >
        <DialogTitle sx={{ pl: 6, pt: 4, fontWeight: 500 }}>
          <span style={{ marginTop: '7px', display: 'inline-block' }}>
          <FileIcon sx={{ color: '#017322', width: '0.9em', marginRight: '15px', marginBottom: '-5px'}} />
          File
          </span>
          {file ? (
            <TextField value={filename} onChange={handleFilenameChange} variant="filled" disabled={isConnected}
              sx={{
                width: 300, ml: 2,
                '& .MuiFilledInput-root:before': { border: 'none' }, '& .MuiFilledInput-input': { paddingLeft: '25px', paddingTop: '10px', paddingBottom: '10px' },
                '& .MuiFilledInput-root': {
                  paddingTop: 0, borderRadius: 1, backgroundColor: '#011c15',
                  '&:hover': { backgroundColor: '#011c15' },
                  '&:focus': { backgroundColor: '#011c15' },
                },
                "& .MuiFilledInput-input.Mui-disabled": {
                  WebkitTextFillColor: "white",
                },
                ".MuiFilledInput-root.Mui-disabled svg": {
                  color: "#d0ffddd1",
                },
                "& .MuiFilledInput-root.Mui-disabled:before": {
                  borderBottomStyle: "none",
                },
                ".MuiFilledInput-root.Mui-disabled": {
                  backgroundColor: "#05271c",
                  border: "none",
                },
                '& .MuiInputAdornment-positionStart': { marginTop: '0px !important' },
              }}
              InputProps={{
                endAdornment: (<InputAdornment sx={{ color: '#009F2A' }} position="end">
                  {fileExtension ? '.' : null}{fileExtension}
                  {isConnected ? <LockIcon sx={{ width: '12px', color: '#d0ffdd', marginLeft: '20px', marginTop: '3px' }} /> : null}
                </InputAdornment>),
              }}
            />
          ) : null}
          <IconButton aria-label="close" onClick={handleClose} size="small" sx={{ position: 'absolute', right: 10, top: 10, color: (theme) => theme.palette.grey[600] }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ width: file ? 1200 : 700, mt: 3, mr: 3.5, mb: 0, ml: 3.5 }}>
          <Box sx={{ backgroundColor: '#011c15', pt: 1.5, pr: 5, pb: 1.5, pl: 5, mb: 2, display: 'flex', justifyContent: 'space-between', borderRadius: 0.5 }}>
            <Box sx={{ mt: 0.5 }}>
              <FileInfo loading={loading} file={file} fileExtension={fileExtension} fileSize={fileSize} />
            </Box>
            <Box>
              {file ? (
                <>
                  <Button sx={{ textTransform: 'none', mr: 2, fontWeight: 500 }} onClick={downloadFile}>Download</Button>
                </>
              ) : null}
              <Button sx={{ textTransform: 'none', fontWeight: 500 }} onClick={() => document.getElementById('uploadFile').click()}>Upload new</Button>
              <input type="file" id="uploadFile" style={{ display: 'none' }} onChange={fileUploadHandler} />
              <Button onClick={handleNewFileDialogOpen} sx={{ textTransform: 'none', ml: 2, fontWeight: 500 }}>Create new</Button>
            </Box>
          </Box>
          {file ? ( <Editor file={file} setFile={setFile} fileExtension={fileExtension} />) : null}
          <Box sx={{ mb: 5, mt: 5, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} size="medium" variant="outlined" sx={{ pr: 4, mr: 2.5, pl: 4, borderRadius: '4px' }} >Cancel</Button>
            <Button onClick={saveAndClose} size="medium" variant="contained" sx={{ pr: 4, pl: 4, borderRadius: '4px' }} >Save</Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={newFileDialogOpen} maxWidth="sm" BackdropProps={{ invisible: false }} sx={{ p: 5 }}>
        <DialogTitle sx={{ fontWeight: 500, ml: 1 }}>
          Create empty file
        </DialogTitle>
        <Box sx={{ pt: 3, pr: 3, pb: 0, pl: 3 }}>
          <TextField
            variant="filled"
            label="Filename"
            value={newFilename}
            onChange={handleNewFilenameChange}
            sx={{
              width: 330,
              '& .MuiFilledInput-root:before': { border: 'none' },
              '& .MuiFilledInput-root': { paddingTop: 0, borderRadius: 1, backgroundColor: '#011c15', '&:hover': { backgroundColor: '#011c15' }, '&:focus': { backgroundColor: '#011c15' } },
            }}
          />
          <TextField
            variant="filled"
            label="File extension"
            value={newFileExtension}
            onChange={handleNewFileExtensionChange}
            onKeyPress={(event) => { if (event.key === '.') event.preventDefault(); }}
            sx={{
              width: 130, ml: 2,
              '& .MuiFilledInput-root:before': { border: 'none' },
              '& .MuiFilledInput-root': { color: '#009F2A', paddingTop: 0, borderRadius: 1, backgroundColor: '#011c15', '&:hover': { backgroundColor: '#011c15' }, '&:focus': { backgroundColor: '#011c15' } },
            }}
          />
        </Box>
        <Box sx={{ mb: 3, mt: 4, mr: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleNewFileDialogClose} size="medium" variant="outlined" sx={{ pr: 4, mr: 2.5, pl: 4, borderRadius: '4px' }} >Cancel</Button>
          <Button onClick={handleNewFileDialogSave} size="medium" variant="contained" sx={{ pr: 4, pl: 4, borderRadius: '4px' }} >Save</Button>
        </Box>
      </Dialog>
    </>
  )
}

export default FileEditor;
