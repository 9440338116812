import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import ActionSelector from '../actions/ActionSelector';

import Grid from '@mui/material/Grid';



const AlgorithmContext = ({ hover, addAction }) => {

  const [showActionSelector, setShowActionSelector] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!hover) setVisible(false);
  }, [hover]);

  const displayActionSelector = () => {
    setVisible(true);
    setShowActionSelector(true);
  };

  return (
    <Grid container spacing={0}>
      <Grid item style={{ width: 260 }}>
        <IconButton onClick={displayActionSelector} variant="contained" sx={{
          pl: 2, pr: 2, mr: 10, pt: 1.2, pb: 1.2,
          backgroundColor: '#008e27',
          borderRadius: '4px',
          boxShadow: 'none',
          fontSize: '13px',
          letterSpacing: '0.2px',
          fontWeight: 500,
          fontFamily: 'Outfit, sans-serif',
        }}>
          <AddIcon sx={{ fontSize: '14px' }} /> &nbsp;&nbsp;Automation
        </IconButton>
      </Grid>
      <Grid item xs style={{ opacity: visible ? 1 : 0, transition: visible ? 'opacity ease 1s' : 'opacity ease 1s 5s' }} onTransitionEnd={() => setShowActionSelector(false)} >
        <ActionSelector addAction={addAction} />
      </Grid>
    </Grid>
  )
}

export default AlgorithmContext;
